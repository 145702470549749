import styled from "styled-components";
import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { blue, darkRed, red } from "../../styles";
import {
  faSpinner,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Panel = styled.div`
  text-align: left;

  iframe {
    width: 100%;
  }

  h2 {
    color: ${blue} !important;
  }

  .gallery-container {
    flex-wrap: wrap;
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }
  ul.list li {
    font-size: 16px;
    ::marker {
      font-size: 18px;
      top: 6px;
      color: #d6410a;
    }
  }
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;
const ActionButton = styled.a`
  font-size: 16px;
  padding: 8px 22px;
  line-height: 1.38;
  transition: background 0.3s;
  background-color: ${red};
  border-color: ${red};

  :hover {
    border-color: ${darkRed};
    background: ${darkRed};
  }

  svg {
    width: 12px;
  }
`;

const Image = styled.img`
  transition: all 300ms ease-in-out 0s;
  width: 100%;
  border-radius: 4px;

  :hover {
    transform: scale3d(1.1, 1.1, 1.1);
  }
`;
const H2 = styled.h2`
  margin-top: 20px;
  color: ${blue};
  font-weight: 600;
  line-height: 1.42857143;
`;

const ImageList = [
  {
    index: 1,
    src: "images/photo-galleries/2023-thumb.jpg",
    alt: "2023 SOT Annual Meeting and ToxExpo",
    href: "https://toxicology.smugmug.com/2023-SOT-Annual-Meeting-and-ToxExpo",
  },
  {
    index: 2,
    src: "images/photo-galleries/2022-thumb.jpg",
    alt: "2022 SOT Annual Meeting and ToxExpo",
    href: "https://toxicology.smugmug.com/2022-SOT-Annual-Meeting-and-ToxExpo",
  },
  {
    index: 3,
    src: "images/photo-galleries/2019-thumb.jpg",
    alt: "2019 SOT Annual Meeting and ToxExpo",
    href: "https://toxicology.smugmug.com/2019-SOT-Annual-Meeting-and-ToxExpo",
  },
  {
    index: 4,
    src: "images/photo-galleries/2018-thumb.jpg",
    alt: "2018 SOT Annual Meeting and ToxExpo",
    href: "https://toxicology.smugmug.com/2018-Annual-Meeting",
  },
  {
    index: 5,
    src: "images/photo-galleries/2017-thumb.jpg",
    alt: "2017 SOT Annual Meeting and ToxExpo",
    href: "https://toxicology.smugmug.com/SOT-2017-Annual-Meeting",
  },
  {
    index: 6,
    src: "images/photo-galleries/2016-thumb.jpg",
    alt: "2016 SOT Annual Meeting and ToxExpo",
    href: "https://toxicology.smugmug.com/SOT2016",
  },
];

const items = [
  "Scientific and Poster Sessions",
  "ToxExpo Exhibit Hall",
  "Component Group Activities",
  "Student and Postdoc Events",
];

const ImageThumb = ({ src, alt, href }) => {
  return (
    <Col md={6} lg={4} className="mb-30">
      <a href={href} target="_blank" rel="noopener noreferrer">
        <Image src={src} alt={alt} />
      </a>
    </Col>
  );
};

const BulletItem = ({ bullet }) => <li>{bullet}</li>;

function PhotoGallery() {
  const [isLoading, setIsLoading] = useState(true);
  const [access, setAccess] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const result = await fetch(`/api/validateUserForPhotoGallery`, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (result.status !== 200) {
          setAccess(false);
          const error = await result.json();
          setError(error.message);
        } else setAccess(true);
      } catch (e) {
        setAccess(false);
        setError(
          "An error occurred when attempting to validate your access to this page."
        );
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <Container>
      {isLoading ? (
        <Panel>
          <Loading>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          </Loading>
        </Panel>
      ) : (
        <>
          {!access && (
            <Panel>
              <h3>Access restricted</h3>
              <p>{error}</p>
            </Panel>
          )}
          {access && (
            <Panel>
              <Row>
                <Col md={12} lg={6}>
                  <a
                    href="https://toxicology.smugmug.com/2024-SOT-Annual-Meeting-and-ToxExpo/n-KzG2Pb"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <iframe
                      title="2024 SOT Annual Meeting and ToxExpo Photo Gallery"
                      src="https://toxicology.smugmug.com/frame/slideshow?key=mnbZZD&speed=3&transition=fade&autoStart=1&captions=0&navigation=0&playButton=0&randomize=0&transitionSpeed=2"
                      width="700"
                      height="380"
                      frameBorder="no"
                      scrolling="no"
                    ></iframe>
                  </a>
                </Col>
                <Col md={12} lg={6}>
                  <H2>2024 SOT Annual Meeting and ToxExpo Photo Gallery</H2>
                  <p className="lead">
                    Explore the moments captured during the 2024 Annual Meeting
                    and ToxExpo in Salt Lake City, Utah.
                  </p>
                  <ul className="list mb-10">
                    {items.map((item, index) => (
                      <BulletItem key={`bullet_id${index}`} bullet={item} />
                    ))}
                  </ul>
                  <ActionButton
                    className="btn btn-dark btn-theme-colored2 mt-10 mb-30"
                    href={
                      "https://toxicology.smugmug.com/2024-SOT-Annual-Meeting-and-ToxExpo/n-KzG2Pb"
                    }
                    data-feathr-click-track="true"
                    data-feathr-link-aids='["5f3d5ab794d4456c741321a7"]'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Access the Gallery{" "}
                    <FontAwesomeIcon
                      icon={faAngleDoubleRight}
                      className="fa ml-5"
                    />
                  </ActionButton>
                </Col>
              </Row>

              <Row className="text-center mb-10 mt-40">
                <Col className="col-md-push-1">
                  <H2>Other Photo Galleries</H2>
                  <p className="lead">
                    Take a journey through the memorable moments captured in
                    these galleries.
                  </p>
                </Col>
              </Row>
              <Row className="mb-40 gallery-container">
                {ImageList.map((image) => (
                  <ImageThumb
                    key={image.index}
                    src={image.src}
                    alt={image.alt}
                    href={image.href}
                  />
                ))}
              </Row>

              <Row className="text-center mb-40">
                <Col>
                  <ActionButton
                    className="btn btn-dark btn-theme-colored2 mt-10 mb-30"
                    href="https://toxicology.smugmug.com/"
                    data-feathr-click-track="true"
                    data-feathr-link-aids='["5f3d5ab794d4456c741321a7"]'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View more{" "}
                    <FontAwesomeIcon
                      icon={faAngleDoubleRight}
                      className="fa ml-5"
                    />
                  </ActionButton>
                </Col>
              </Row>
            </Panel>
          )}
        </>
      )}
    </Container>
  );
}

export default PhotoGallery;

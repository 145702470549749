import styled from 'styled-components';
import React, {useState, useCallback, useRef, useEffect} from "react";
import {blue, PrinterBreak, red} from "../styles";
import {
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useNavigate, useLocation} from "react-router-dom";

const SectionContent = styled.div`
`;
const Panel = styled.div`
  text-align: left;
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red}
`;
const Button = styled.a`
  margin: 15px 15px 10px 0;
`;
const P = styled.p`
  margin-bottom: 40px;
`;

function Book({linkPrefix = 'https://www.toxicology.org'}) {
    const [isLoading, setIsLoading] = useState(true);
    const [hasDownloaded, setHasDownloaded] = useState(false);
    const [url, setUrl] = useState('');
    const [error, setError] = useState();
    const navigate = useNavigate();
    const {
        pathname,
        search,
    } = useLocation();
    const queryParams = new URLSearchParams(search)
    const code = queryParams.get("code")

    useEffect(() => {(async () => {
        try {
            const result = await fetch(`/api/getBookUrl?code=${code}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if ( result.status === 401 ) {
                navigate('/login?redirect=' + encodeURIComponent(pathname + search));
            } else if ( result.status === 403 ) {
                setError("If you have not registered for this course and would like access to the course materials, please register on the <a href='https://www.toxicology.org/education/ce/onlineCourses.asp'>CEd-Tox page</a>.");
            } else if ( result.status === 404 ) {
                setError("Sorry, the resource was not found.");
            } else {
                const { url } = await result.json();
                setUrl(url);
            }

        } catch (e) {
            setError("Unfortunately an error occurred while retrieving this resource");
        }
        setIsLoading(false);
    })()}, []);


    return (
        <SectionContent>
            {
                isLoading ? (
                    <Panel>
                        <Loading>
                            <FontAwesomeIcon icon={faSpinner} className="fa-spin"/>
                        </Loading>
                    </Panel>
                ) : error ? (
                    <div>
                        <span className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{__html: error}}/>
                    </div>
                ) : (
                    <Panel>
                        {
                            hasDownloaded ? (
                                <P>Thank you for downloading this resource.</P>
                            ) : (
                                <P>
                                    Please click the link to download the PDF<br/>
                                    <Button
                                        className="btn btn-primary col-xs-12 col-md-4 col-lg-3"
                                        href={url}
                                        download={`${code}.pdf`}
                                    >Download PDF</Button>
                                </P>
                            )
                        }
                    </Panel>
                )
            }

        </SectionContent>
    );
}

export default Book;

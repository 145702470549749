export const allComponentGroups = {
    "Regional_Chapter": [
        'Allegheny-Erie_Regional_Chapter',
        'Central_States_Regional_Chapter',
        'Lone_Star_Regional_Chapter',
        'Michigan_Regional_Chapter',
        'Mid-Atlantic_Regional_Chapter',
        'Midwest_Regional_Chapter',
        'Mountain_West_Regional_Chapter',
        'National_Capital_Area_Regional_Chapter',
        'North_Carolina_Regional_Chapter',
        'Northeast_Regional_Chapter',
        'Northern_California_Regional_Chapter',
        'Northland_Regional_Chapter',
        'Ohio_Valley_Regional_Chapter',
        'Pacific_Northwest_Regional_Chapter',
        'South_Central_Regional_Chapter',
        'Southeastern_Regional_Chapter',
        'Southern_California_Regional_Chapter',
    ],
    "Special_Interest_Group": [
        'American_Association_of_Chinese_in_Toxicology',
        'Arab_Toxicologists_Association',
        'Association_of_Scientists_of_Indian_Origin',
        'Hispanic_Organization_of_Toxicologists',
        'Korean_Toxicologists_Association_in_America',
        'Out_Toxicologists_and_Allies',
        'Toxicologists_of_African_Origin',
        'Women_in_Toxicology',
    ],
    "Specialty_Section": [
        'Biological_Modeling_Specialty_Section',
        'Biotechnology_Specialty_Section',
        'Carcinogenesis_Specialty_Section',
        'Cardiovascular_Toxicology_Specialty_Section',
        'Clinical_and_Translational_Toxicology_Specialty_Section',
        'Comparative_Toxicology,_Pathology,_and_Veterinary_Specialty_Section',
        'Computational_Toxicology_Specialty_Section',
        'Dermal_Toxicology_Specialty_Section',
        'Drug_Discovery_Toxicology_Specialty_Section',
        'Ethical,_Legal,_Forensics,_and_Societal_Issues_Specialty_Section',
        'Exposure_Specialty_Section',
        'Food_Safety_Specialty_Section',
        'Immunotoxicology_Specialty_Section',
        'In_Vitro_and_Alternative_Methods_Specialty_Section',
        'Inhalation_and_Respiratory_Specialty_Section',
        'Mechanisms_Specialty_Section',
        'Medical_Device_and_Combination_Product_Specialty_Section',
        'Metals_Specialty_Section',
        'Mixtures_Specialty_Section',
        'Molecular_and_Systems_Biology_Specialty_Section',
        'Nanoscience_and_Advanced_Materials_Specialty_Section',
        'Neurotoxicology_Specialty_Section',
        'Occupational_and_Public_Health_Specialty_Section',
        'Ocular_Toxicology_Specialty_Section',
        'Regulatory_and_Safety_Evaluation_Specialty_Section',
        'Reproductive_and_Developmental_Toxicology_Specialty_Section',
        'Risk_Assessment_Specialty_Section',
        'Stem_Cells_Specialty_Section',
        'Sustainable_Chemicals_through_Contemporary_Toxicology_Specialty_Section',
    ],
};

export const componentGroupTypesByComponentGroup = Object.keys(allComponentGroups).reduce((acc, type) => {
    let list = allComponentGroups[type];
    list.map(cg => acc[cg] = type);
    return acc;
}, {})
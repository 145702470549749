export const getCourse = async (courseCode, {useAdmin = false, titleOnly = false} = {}) => {
    let url = `/api/getCourse?code=${courseCode}`;
    if ( useAdmin ) {
        url += `&useAdmin=true`;
    }
    if ( titleOnly ) {
        url += `&titleOnly=true`;
    }
    return await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
    });
}
export const getEndowment = async (id) => {
    let url = '';
    if ( !id ) {
        url = `/api/getEndowment?id=1`;
    } else {
        url = `/api/getEndowment?id=${id}`;
    }
    return await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
    });
}
export const listCourses = async () => {
    let url = `/api/listCourses`;
    return await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
    });
}
export const listEndowments = async () => {
    let url = `/api/listEndowments`;
    return await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
    });
}
export const getProgramList = async () => {
    let url = `/api/listPrograms`;
    let key = `get-${url}`;
    const itemStr = localStorage.getItem(key)
    if (itemStr) {
        const item = JSON.parse(itemStr)
        const now = new Date()
        // compare the expiry time of the item with the current time
        if (now.getTime() <= item.expiry) {
            return {status: 200, json: item.json}
        }
    }

    let result = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'default',
        headers: {
            'Content-Type': 'application/json'
        },
    });

    let status = result.status;
    let json = await result.json();
    if ( status === 200 ) {
        const now = new Date()
        const item = {
            json,
            expiry: now.getTime() + 1000 * 60 * 60,
        };
        localStorage.setItem(key, JSON.stringify(item))
    }

    return {status, json};
}
export const getEndowmentList = async () => {
    let url = `/api/listEndowments`;
    return await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
    });
}
export const getSAS = async imageSuffix => {
    let url = `/api/getSAS?imageSuffix=${imageSuffix}`;
    return await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
    });
}
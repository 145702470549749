import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { blue, red } from "../../styles";
import {
  faAdd,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { getEndowment } from "../../api";
import { uploadImage } from "../../services/uploadImage";
import {
  allComponentGroups,
  componentGroupTypesByComponentGroup,
} from "./componentGroups";

const SectionContent = styled.div``;
const LinkWrapper = styled.div`
  text-align: left;
  margin-bottom: 20px;
`;
const Panel = styled.div`
  text-align: left;
  h2 {
    color: ${blue} !important;
  }
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;
const Button = styled.a`
  margin: 15px 15px 10px 0;
`;
const P = styled.p`
  margin-bottom: 40px;
`;
const Field = styled.div`
  width: 80%;
  display: flex;
  margin-bottom: 5px;
`;
const Input = styled.input`
  margin-left: 5px;
  flex: 1;
`;
const TextArea = styled.textarea`
  margin-left: 5px;
  flex: 1;
  height: 245px;
`;
const TextAreaSmall = styled.textarea`
  margin-left: 5px;
  flex: 1;
  height: 100px;
`;
const TextAreaSmallest = styled.textarea`
  margin-left: 5px;
  flex: 1;
  height: 28px;
`;
const Label = styled.label`
  text-align: right;
  width: 220px;
`;
const SmallInput = styled.input`
  margin-left: 5px;
  min-width: 200px;
`;
const SmallCheckbox = styled.input`
  margin-left: 5px;
`;
const FileInput = styled.input`
  margin-left: 5px;
`;
const SmallSelect = styled.select`
  margin-left: 5px;
  min-width: 200px;
`;
const Select = styled.select`
  margin-left: 5px;
  flex: 1;
`;
const Image = styled.img`
  max-width: 200px;
  max-height: 200px;
`;
const CgWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const CgButton = styled.button`
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function CourseAdmin() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  let { endowmentId } = useParams();

  const [name, setName] = useState("");
  const [contributors, setContributors] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [description, setDescription] = useState("");
  const [fullDescription, setFullDescription] = useState("");
  const [link, setLink] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [tags, setTags] = useState("");
  const [flagResearchExcellence, setFlagResearchExcellence] = useState(false);
  const [flagPromotingDiversity, setFlagPromotingDiversity] = useState(false);
  const [flagMissionFunds, setFlagMissionFunds] = useState(false);
  const [flagTravel, setFlagTravel] = useState(false);
  const [flagDevelopment, setFlagDevelopment] = useState(false);
  const [careerLevel, setCareerLevel] = useState(false);
  const [componentGroupType, setComponentGroupType] = useState(false);
  const [componentGroupNameArray, setComponentGroupNameArray] = useState([]);

  const onUpdateName = (e) => {
    setName(e.target.value);
  };
  const onUpdateDescription = (e) => {
    setDescription(e.target.value);
  };
  const onUpdateFullDescription = (e) => {
    setFullDescription(e.target.value);
  };
  const onUpdateLink = (e) => {
    setLink(e.target.value);
  };
  const onUpdateImageFile = async (e) => {
    if (
      !e ||
      !e.target ||
      !e.target.files ||
      !e.target.files.length ||
      !e.target.files[0]
    ) {
      return;
    }
    setImageUrl(null);

    let file = e.target.files[0];
    setImageFile(file);
    let url = await uploadImage(file, file.name);
    setImageUrl(url);
  };
  const onUpdateTags = (e) => {
    setTags(e.target.value);
  };
  const onUpdateContributors = (e, index) => {
    let v = e.target.value;
    const tempContributors = contributors.concat();
    tempContributors[index] = v;
    setContributors(tempContributors);
  };
  const removeContributor = (index) => {
    const tempContributors = contributors
      .concat()
      .filter((r, idx) => idx !== index);
    setContributors(tempContributors);
  };
  const addContributor = () => {
    const tempContributors = contributors.concat();
    tempContributors.push("");
    setContributors(tempContributors);
  };
  const onUpdateRecipients = (e, index) => {
    let v = e.target.value;
    const tempRecipients = recipients.concat();
    tempRecipients[index] = v;
    setRecipients(tempRecipients);
  };
  const removeRecipient = (index) => {
    const tempRecipients = recipients
      .concat()
      .filter((r, idx) => idx !== index);
    setRecipients(tempRecipients);
  };
  const addRecipient = () => {
    const tempRecipients = recipients.concat();
    tempRecipients.push("");
    setRecipients(tempRecipients);
  };

  useEffect(() => {
    (async () => {
      try {
        const result = await getEndowment(endowmentId);
        if (result.status === 401) {
          navigate("/login?redirect=" + encodeURIComponent(pathname + search));
        } else if (result.status === 403) {
          setError(
            "If you have not registered for this endowment and would like access to the endowment materials, please register on the <a href='https://www.toxicology.org/education/ce/onlineCourses.asp'>CEd-Tox page</a>."
          );
        } else if (result.status === 404) {
          setError("Sorry, the resource was not found.");
        } else {
          const {
            name,
            description,
            fullDescription,
            link,
            imageUrl,
            tags,
            contributors,
            recipients,
            flagResearchExcellence,
            flagPromotingDiversity,
            flagMissionFunds,
            flagTravel,
            flagDevelopment,
            careerLevel,
            componentGroupType,
            componentGroupNameArray,
          } = await result.json();
          setName(name);
          setDescription(description);
          setFullDescription(fullDescription);
          setLink(link);
          setImageUrl(imageUrl);
          setTags(tags);
          setContributors(contributors);
          setRecipients(recipients);
          setFlagResearchExcellence(flagResearchExcellence);
          setFlagPromotingDiversity(flagPromotingDiversity);
          setFlagMissionFunds(flagMissionFunds);
          setFlagTravel(flagTravel);
          setFlagDevelopment(flagDevelopment);
          setCareerLevel(careerLevel);
          setComponentGroupType(componentGroupType);
          setComponentGroupNameArray(
            componentGroupNameArray ? componentGroupNameArray : []
          );
        }
      } catch (e) {
        setError(
          "Unfortunately an error occurred while retrieving this resource"
        );
        console.log(e);
      }
      setIsLoading(false);
    })();
  }, []);

  const save = async () => {
    setIsLoading(true);
    let tempContributors = [];
    if (contributors.length) {
      tempContributors = contributors.concat().filter((c) => c);
      setContributors(tempContributors);
    }
    let tempRecipients = [];
    if (recipients.length) {
      tempRecipients = recipients.concat().filter((r) => r);
      setRecipients(tempRecipients);
    }
    try {
      const result = await fetch(`/api/setEndowment`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: endowmentId,
          name,
          description,
          fullDescription,
          link,
          imageUrl,
          tags,
          contributors: tempContributors,
          recipients: tempRecipients,
          flagResearchExcellence,
          flagPromotingDiversity,
          flagMissionFunds,
          flagTravel,
          flagDevelopment,
          careerLevel,
          componentGroupType,
          componentGroupNameArray,
        }),
      });
      alert(
        result.status === 200
          ? "Content Saved"
          : "There was an error saving changes"
      );
    } catch (e) {
      console.log(e);
      alert("Unfortunately an error occurred while saving changes");
    }
    setIsLoading(false);
  };

  const onUpdateFlagResearchExcellence = (event) => {
    let target = event.target;
    let checked = target.checked;
    setFlagResearchExcellence(checked);
  };
  const onUpdateFlagPromotingDiversity = (event) => {
    let target = event.target;
    let checked = target.checked;
    setFlagPromotingDiversity(checked);
  };
  const onUpdateFlagMissionFunds = (event) => {
    let target = event.target;
    let checked = target.checked;
    setFlagMissionFunds(checked);
  };
  const onUpdateFlagTravel = (event) => {
    let target = event.target;
    let checked = target.checked;
    setFlagTravel(checked);
  };
  const onUpdateFlagDevelopment = (event) => {
    let target = event.target;
    let checked = target.checked;
    setFlagDevelopment(checked);
  };
  const onUpdateCareerLevel = (e) => {
    setCareerLevel(e.target.value);
  };
  const onUpdateComponentGroupArray = (e) => {
    setComponentGroupType(
      e.target.value ? componentGroupTypesByComponentGroup[e.target.value] : ""
    );
    const newGName = componentGroupNameArray.concat();
    newGName.push(e.target.value ? e.target.value : "");
    setComponentGroupNameArray(newGName);
  };
  const deleteGName = (val) => {
    const newGName = componentGroupNameArray
      .concat()
      .filter((cg) => cg !== val);
    setComponentGroupNameArray(newGName);
  };

  return (
    <SectionContent>
      <LinkWrapper>
        <Link to="/admin/endowment">&lt; Back to Endowment List</Link>
      </LinkWrapper>
      {isLoading ? (
        <Panel>
          <Loading>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          </Loading>
        </Panel>
      ) : error ? (
        <div>
          <span
            className="alert alert-danger"
            role="alert"
            dangerouslySetInnerHTML={{ __html: error }}
          />
        </div>
      ) : (
        <Panel>
          <Field>
            <Label>Name:</Label>
            <TextAreaSmallest value={name} onChange={onUpdateName} />
          </Field>
          <Field>
            <Label>Description:</Label>
            <TextAreaSmall value={description} onChange={onUpdateDescription} />
          </Field>
          <Field>
            <Label>Full Description:</Label>
            <TextArea
              value={fullDescription}
              onChange={onUpdateFullDescription}
            />
          </Field>
          <Field>
            <Label>Link:</Label>
            <SmallInput value={link} onChange={onUpdateLink} />
          </Field>
          <Field>
            <Label>Image:</Label>
            <FileInput type="file" onChange={onUpdateImageFile} />
            {imageFile ? imageFile.name : null}
            {imageUrl ? <Image src={imageUrl} /> : null}
          </Field>

          <Field>
            <Label>Component group:</Label>
            <CgWrap>
              <div style={{ display: "flex" }}>
                {componentGroupNameArray &&
                  componentGroupNameArray.map((cg) => (
                    <CgButton key={cg} onClick={() => deleteGName(cg)}>
                      {cg.replaceAll("_", " ")}
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="fa-envelope-o"
                        aria-hidden="true"
                      />
                    </CgButton>
                  ))}
              </div>
              <SmallSelect
                value={componentGroupNameArray}
                onChange={onUpdateComponentGroupArray}
              >
                <option componentgrouptype="" value=""></option>
                {Object.keys(allComponentGroups).map((cgType) =>
                  allComponentGroups[cgType].map((cg) => (
                    <option key={cg + "opt"} value={cg}>
                      {cg.replaceAll("_", " ")}
                    </option>
                  ))
                )}
              </SmallSelect>
            </CgWrap>
          </Field>
          <Field>
            <Label>Career Level:</Label>
            <SmallSelect value={careerLevel} onChange={onUpdateCareerLevel}>
              <option value=""></option>
              <option value="early">Early Level</option>
              <option value="mid">Mid Level</option>
              <option value="senior">Senior Level</option>
            </SmallSelect>
          </Field>
          <Field>
            <Label>Research excellence achievement:</Label>
            <SmallCheckbox
              type="checkbox"
              value={flagResearchExcellence}
              checked={flagResearchExcellence}
              onChange={onUpdateFlagResearchExcellence}
            />
          </Field>
          <Field>
            <Label>Promoting diversity:</Label>
            <SmallCheckbox
              type="checkbox"
              value={flagPromotingDiversity}
              checked={flagPromotingDiversity}
              onChange={onUpdateFlagPromotingDiversity}
            />
          </Field>
          <Field>
            <Label>SOT mission funds:</Label>
            <SmallCheckbox
              type="checkbox"
              value={flagMissionFunds}
              checked={flagMissionFunds}
              onChange={onUpdateFlagMissionFunds}
            />
          </Field>
          <Field>
            <Label>Travel to attend scientific meeting:</Label>
            <SmallCheckbox
              type="checkbox"
              value={flagTravel}
              checked={flagTravel}
              onChange={onUpdateFlagTravel}
            />
          </Field>
          <Field>
            <Label>Career development</Label>
            <SmallCheckbox
              type="checkbox"
              value={flagDevelopment}
              checked={flagDevelopment}
              onChange={onUpdateFlagDevelopment}
            />
          </Field>
          <Field>
            <Label>Tags:</Label>
            <Input value={tags} onChange={onUpdateTags} />
          </Field>
          <Field>
            <Label>Contributors:</Label>
            {contributors.length
              ? contributors.map((c, cIdx) => (
                  <div key={"contributor" + cIdx}>
                    <SmallInput
                      value={c}
                      onChange={(e) => onUpdateContributors(e, cIdx)}
                    />
                    <Button onClick={() => removeContributor(cIdx)}>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="fa-envelope-o"
                        aria-hidden="true"
                      />
                    </Button>
                  </div>
                ))
              : null}
            <Button onClick={addContributor}>
              <FontAwesomeIcon
                icon={faAdd}
                className="fa-envelope-o"
                aria-hidden="true"
              />
            </Button>
          </Field>
          <Field>
            <Label>Recipients:</Label>
            {recipients.length
              ? recipients.map((r, rIdx) => (
                  <div key={"recipient" + rIdx}>
                    <SmallInput
                      value={r}
                      onChange={(e) => onUpdateRecipients(e, rIdx)}
                    />
                    <Button onClick={() => removeRecipient(rIdx)}>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="fa-envelope-o"
                        aria-hidden="true"
                      />
                    </Button>
                  </div>
                ))
              : null}
            <Button onClick={addRecipient}>
              <FontAwesomeIcon
                icon={faAdd}
                className="fa-envelope-o"
                aria-hidden="true"
              />
            </Button>
          </Field>

          <button onClick={save}>Submit</button>
          <br />
          <br />
          <br />
        </Panel>
      )}
    </SectionContent>
  );
}

export default CourseAdmin;

import React from 'react';
import styled from "styled-components";
import {blue, orange, gray} from '../../styles';
import ReactMarkdown from 'react-markdown';
import { ToWords } from 'to-words';

const toWords = new ToWords();

const OfficeHeader = styled.h2`
  border-bottom: 5px solid ${blue};
`;
const OfficeName = styled.span`
  text-transform: uppercase;
  color: ${orange};
  font-weight: bold;
  font-size: 32px;
`;
const OfficeNote = styled.em`
  font-weight: ${gray};
  font-size: 18px;
`;

const OfficeWrapper = styled.div`
  margin: 0 0 60px;
  @media print {
    margin: 0;
  }
`;

const CandidateWrapper = styled.div`
  color: black;
  margin-top: 20px;
  margin-bottom: 20px;
  @media print {
    page-break-after: always;
    font-size: 10px;
  }
`;
const CandidateCheck = styled.div`
  color: black;
  font-weight: bold;
  font-size: 22px;
  line-height: 1.6;
  margin-bottom: 0;
  @media print {
    font-size: 10px;
  }
`;
const CandidateName = styled.p`
  font-weight: bolder;
  color: ${blue};
  margin: 0;
`;
const CandidateTitles = styled.p`
  color: ${gray};
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
  @media print {
    font-size: 10px;
  }
`;
const ProfileHr = styled.hr`
  margin: 12px 0 6px;
`;
const Photo = styled.img`
  border-radius: 12px;
  margin: 20px 0;
`;
const InfoLabel = styled.span`
  font-weight: bold;
  color: ${blue};
`
const WriteInLabel = styled.label`
  width: 100%;
`
const WriteInInput = styled.input`
  font-weight: normal;
  color: black;
  margin: 12px 0;
  padding: 2px 16px;
  width: 100%;
  @media (min-width: 992px) {
    width: 50%;
  }
`
const LeftSection = styled.div`
    @media print {
      width: 25%;
    }
`
const RightSection = styled.div`
    @media print {
      width: 75%;
    }
`

function CandidateFn({
                    office,
                    candidate,
                    handleOptionChange,
                    selected,
                   }) {
    return (
        <React.Fragment>
            <CandidateWrapper className="row">
                <LeftSection className="col-xs-12 col-md-6 col-lg-3">
                    <CandidateCheck className="col-xs-12 form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value={candidate.id}
                            id={`checkbox-${office.id}-${candidate.id}`}
                            name={office.id}
                            onChange={handleOptionChange}
                            checked={selected}
                        />
                        <label className="form-check-label" htmlFor={`checkbox-${office.id}-${candidate.id}`}>
                            <CandidateName>{candidate.name}</CandidateName>
                            <CandidateTitles>{candidate.titles}</CandidateTitles>
                            <ProfileHr/>
                            <CandidateTitles>
                                {office.candidateTitle}{candidate.officeGroup ? `—${candidate.officeGroup}` : ''}
                            </CandidateTitles>
                        </label>
                    </CandidateCheck>
                    <div className="row">
                        <div className="col-xs-12">
                            <Photo
                                src={candidate.image}
                                alt={`Photograph of ${candidate.name}`}
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-xs-12">
                            <p><InfoLabel>Employer:</InfoLabel><br/>{candidate.employer} </p>
                            <p><InfoLabel>Year Joined SOT:</InfoLabel><br/>{candidate.yearJoined}</p>
                            <p><InfoLabel>Education:</InfoLabel><br/>{candidate.schoolsAttended}</p>
                        </div>
                    </div>
                </LeftSection>

                <RightSection className="col-lg-9 col-md-6 col-xs-12">
                    {
                        candidate.sotElectedPositions && candidate.sotElectedPositions.trim().toLowerCase() !== 'n/a' ? (
                            <p><InfoLabel>SOT-Elected Positions:</InfoLabel> {candidate.sotElectedPositions}</p>
                        ) : null
                    }

                    <InfoLabel>Memberships, Chairs, and/or Offices held in SOT Committees, Regional Chapters,
                        Special Interest Groups, Specialty Sections, formerly or currently, and SOT Awards:
                    </InfoLabel><ReactMarkdown children={`${candidate.memberships}`}></ReactMarkdown>

                    {candidate.experience.map((experience, index) => (
                        <React.Fragment key={index}>
                            {index === 0 ? (<InfoLabel>Experience:&nbsp;</InfoLabel>) : null}
                            <ReactMarkdown>{experience}</ReactMarkdown>
                        </React.Fragment>
                    ))}

                    {candidate.goals.map((goal, index) => (
                        <React.Fragment key={index}>
                            {index === 0 ? (<InfoLabel>Goals for SOT:&nbsp;</InfoLabel>) : null}
                            <ReactMarkdown>{goal}</ReactMarkdown>
                        </React.Fragment>
                    ))}

                </RightSection>

            </CandidateWrapper>
            <div className="row">
                <div className="col-xs-12">
                    <hr/>
                </div>
            </div>
        </React.Fragment>
    );
}

const Candidate = React.memo(CandidateFn, (prevProps, nextProps) => {
    return nextProps.handleOptionChange === prevProps.handleOptionChange &&
        nextProps.selected === prevProps.selected &&
        nextProps.office.id === prevProps.office.id &&
        nextProps.candidate.id === prevProps.candidate.id;
});

function Office({
                    office,
                    toggleCandidateForOfficeId,
                    selectedCandidates,     // [{type: string, writeInIndex: number, writeInName: string, id: string}]
                }) {

    const handleOptionChange = React.useCallback((event) => {
        let target = event.target;
        let checked = target.checked;
        let id = target.value
        if ( typeof id === 'string' && id.startsWith('writeIn-') ) {
            toggleCandidateForOfficeId({
                type: 'writeIn',
                writeInIndex: parseInt(id.substring('writeIn-'.length)),
                writeInName: '', // because this only happens when turning this off
            }, office.id, checked);
        } else {
            toggleCandidateForOfficeId({
                type: 'normal',
                id,
            }, office.id, checked);
        }
    }, [toggleCandidateForOfficeId, office.id])

    const updateTextBox = event => {
        let target = event.target;
        let writeInIndex = parseInt(target.name);
        let writeInName = target.value
        toggleCandidateForOfficeId({
            type: 'writeIn',
            writeInIndex,
            writeInName,
        }, office.id, true);
    };

    return (
        <React.Fragment>
            <OfficeWrapper className="col-xs-12">

                <div className="row">
                    <div className="col-xs-12">
                        <OfficeHeader>
                            <OfficeName>{office.name} </OfficeName>
                            <OfficeNote>({office.voteFor > 1 ? `Vote for no more than ${toWords.convert(office.voteFor).toLowerCase()}` : 'Vote for only one'})</OfficeNote>
                        </OfficeHeader>
                    </div>
                </div>

                {
                    office.candidates.map(candidate => {
                        let selected = selectedCandidates.findIndex(selectedCandidate => selectedCandidate.type === 'normal' && selectedCandidate.id === candidate.id) >= 0;
                        return (
                            <Candidate
                                office={office}
                                candidate={candidate}
                                handleOptionChange={handleOptionChange}
                                selected={selected}
                                key={candidate.id}
                            />
                        );
                    })
                }


                {
                    Array.from(Array(1), (e, i) => {
                        let selectedCandidate = selectedCandidates.find(selectedCandidate => selectedCandidate.type === 'writeIn' && selectedCandidate.writeInIndex === i);
                        let selected = !!selectedCandidate;
                        let writeInName = selectedCandidate ? selectedCandidate.writeInName : '';
                        return (
                            <CandidateWrapper className="row" key={i}>
                                <div className="col-xs-12">
                                    <CandidateCheck className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={`writeIn-${i}`}
                                            id={`checkbox-${office.id}-writein-${i}`}
                                            name={office.id}
                                            checked={selected}
                                            onChange={handleOptionChange}
                                        />
                                        <WriteInLabel className="form-check-label" htmlFor={`checkbox-${office.id}-writein-${i}`}>
                                            <CandidateName>Write-In Candidate for {office.name}: &nbsp;</CandidateName>
                                            <WriteInInput type="text" name={i} value={writeInName} onChange={updateTextBox}/>
                                        </WriteInLabel>
                                    </CandidateCheck>
                                </div>
                            </CandidateWrapper>
                        );
                    })
                }

                <div className="row">
                    <div className="col-xs-12">
                        <hr/>
                    </div>
                </div>
            </OfficeWrapper>
        </React.Fragment>
    )
}

export default React.memo(Office, (prevProps, nextProps) => {
    if ( nextProps.office.id !== prevProps.office.id ||
            nextProps.toggleCandidateForOfficeId !== prevProps.toggleCandidateForOfficeId ||
            (nextProps.selectedCandidates || []).length !== (prevProps.selectedCandidates || []).length
    ) {
        return false;
    }
    for ( let i = 0; i < nextProps.selectedCandidates.length; i++ ) {
        let nextPropsSelectedCandidate = nextProps.selectedCandidates[i];
        let prevPropsSelectedCandidate = prevProps.selectedCandidates[i];
        if ( nextPropsSelectedCandidate.type !== prevPropsSelectedCandidate.type ) {
            return false;
        }
        if ( nextPropsSelectedCandidate.type === 'writeIn' ) {
            if (nextPropsSelectedCandidate.writeInIndex !== prevPropsSelectedCandidate.writeInIndex ||
                nextPropsSelectedCandidate.writeInName !== prevPropsSelectedCandidate.writeInName) {
                return false;
            }
        } else {
            if (nextPropsSelectedCandidate.id !== prevPropsSelectedCandidate.id ) {
                return false;
            }
        }
    }
    return true;
});
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import {
  blue,
  red,
  standardP,
  standardH3,
  grayBoxes,
  orange,
} from "../../styles";
import {
  faSpinner,
  faSearch,
  faCaretUp,
  faCaretDown,
  faAnglesRight,
  faSliders,
  faClose,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import { getEndowmentList } from "../../api";
import Modal from "react-modal";
import { useWindowWidth } from "../../hooks";

const borderRadius = "5px";

const PaginationSection = styled.div`
  align-self: center;
  margin: 25px auto 35px auto;
`;
const PageButton = styled.button`
  background-color: ${grayBoxes};
  border-radius: ${borderRadius};
  border: 0px solid transparent;
  font-weight: bold;
  margin: 10px 5px;
  padding: 2px 12px;
`;
const PageButtonSelected = styled.button`
  background-color: ${blue};
  color: white;
  border-radius: ${borderRadius};
  border: 0px solid transparent;
  font-weight: bold;
  margin: 10px 5px;
  padding: 2px 12px;
`;
const PageSpacer = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0px;
  padding: 2px 6px;
`;
const LeftRightButton = styled.button`
  background-color: transparent;
  border: 0px solid transparent;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0px;
  padding: 2px 12px;
`;
const SectionContent = styled.div``;
const Panel = styled.div`
  text-align: left;
  h2 {
    color: ${blue} !important;
    font-weight: 600;
  }
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;
const TitleSection = styled.div`
  text-align: center;
`;
const Title = styled.h2`
  font-size: 28px;
  color: ${blue};
  font-weight: 600;
`;
const Description1 = styled.p`
  color: ${standardH3};
  max-width: 100%;
  text-align: center;
  display: inline-block;
  margin-top: 15px;
  margin-left: 0px;
  margin-right: 0px;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 300;
`;
const Description2 = styled.p`
  color: ${standardP};
  max-width: 100%;
  text-align: center;
  display: inline-block;
  margin-left: 0px;
  margin-right: 0px;
  line-height: 1.2;
  font-size: 14px;
  font-weight: 300;
`;
const SearchBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
const SearchBoxContainer = styled.div`
  margin: 20px auto;
  display: flex;
  max-width: 350px;
  width: 100%;
`;

const SearchBox = styled.input`
  border-top-left-radius: ${borderRadius};
  border-bottom-left-radius: ${borderRadius};
  font-weight: normal;
  color: black;
  margin: 0;
  padding: 8px 16px;
  width: 100%;
  border: 1px solid gray;
  border-right: 0px solid transparent;
`;
const SearchButton = styled.button`
  border-top-right-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
  background-color: ${orange};
  border: 1px solid ${orange};
  color: white;
  padding: 0px 20px;
`;
const MainArea = styled.div`
  display: flex;
  flex-direction: row;
`;
const LeftSection = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
    width: 25%;
  }
`;
const MainSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const FilterTitle = styled.h3`
  color: ${standardH3};
  font-size: 20px;
`;

const SortByContainer = styled.button`
  border: 0px solid transparent;
  background-color: transparent;
  align-self: flex-end;
`;
const Bold = styled.span`
  font-weight: bold;
  margin-left: 3px;
  color: ${standardH3};
`;
const EndowmentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
const EndowmentWrapper = styled.div`
  padding: 6px;
  @media (min-width: 768px) {
    flex: 50%;
    max-width: 50%;
  }
`;
const EndowmentContainer = styled.div`
  background-color: ${grayBoxes};
  width: 100%;
  margin: 5px 0;
  padding: 35px;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
  }
`;
const EndowmentHeader = styled.div`
  display: flex;
  flex-direction: row;
`;
const TitleAndSummaryWrapper = styled.div`
  @media (min-width: 768px) {
    height: 215px;
    max-height: 215px;
  }
`;
const ProgramTitle = styled.h3`
  flex: 1;
  font-size: 24px;
  max-height: 57px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const ProgramSummary = styled.div`
  font-size: 14px;
  width: 100%;
  margin: 12px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  @media (min-width: 768px) {
    height: 120px;
    max-height: 120px;
  }
`;
const ReadMore = styled.button`
  float: right;
  border-radius: ${borderRadius};
  background-color: ${orange};
  border: 1px solid ${orange};
  color: white;
  padding: 4px 20px;
  align-self: flex-end;
`;

const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
`;
const FilterGroupTitle = styled.p`
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 16px;
`;
const FilterGroup = styled.div`
  margin-left: 15px;
`;
const ComponentGroupTitle = styled.p`
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 15px;
  margin-top: 6px;
`;
const FilterItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
  @media (min-width: 768px) {
    margin: 0px 0px 5px;
  }
`;
const FilterCheckbox = styled.input`
  margin-top: 6px;
  margin-right: 12px;
  align-self: flex-start;
`;
const FilterItemName = styled.label`
  margin-bottom: 0;
`;
const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (min-width: 992px) {
    justify-content: flex-end;
  }
`;
const FilterByContainer = styled.button`
  border-radius: ${borderRadius};
  border: 2px solid ${blue};
  color: ${standardH3};
  background-color: transparent;
  @media (min-width: 992px) {
    display: none;
  }
`;
const CloseButtonFixed = styled.button`
  border: 0px solid transparent;
  position: fixed;
  top: 0;
  right: 0;
  padding: 18px 25px;
  background-color: white;
`;
const CloseButton = styled.button`
  border: 0px solid transparent;
  position: absolute;
  top: 0;
  right: 0;
  padding: 18px 25px;
  background-color: white;
`;
const AwardTypeButton = styled.button`
  display: none;
  background-color: transparent;
  border: 0px solid transparent;
  padding: 10px 10px 10px 0;
`;
const ModalTopHR = styled.hr`
  margin: 28px 0 18px;
`;
const ModalSection = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  @media (min-width: 992px) {
    width: 65vw;
    max-width: 65vw;
  }
`;
const EndModalLeft = styled.div`
  display: flex;
  flex-direction: column;
`;
const EndModalImg = styled.img`
  width: 262px;
  min-width: 262px;
`;
const EndModalRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: 768px) {
    margin-left: 16px;
  }
`;
const ModalTitle = styled.h3`
  font-size: 28px;
  color: ${blue};
  margin-bottom: 12px;
`;
const ModalDescription = styled.p``;

const ModalLink = styled.button`
  float: right;
  border-radius: ${borderRadius};
  background-color: ${orange};
  border: 1px solid ${orange};
  color: white;
  padding: 4px 20px;
`;
function EndowmentPage({ linkPrefix = "https://www.toxicology.org" }) {
  const PerPage = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [endowmentsJson, setEndowmentsJson] = useState();
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("A to Z");
  const [sortByDirection, setSortByDirection] = useState(1);
  const [error, setError] = useState();
  const [filterModal, setFilterModal] = useState(false);
  const [expandedCGs, setExpandedCGs] = useState([
    "regional",
    "special",
    "specialty",
  ]);
  const navigate = useNavigate();
  const [infoModalOpen, setInfoModalOpen] = useState(null);
  const [flagResearchExcellence, setFlagResearchExcellence] = useState(false);
  const [flagScientificMerit, setFlagScientificMerit] = useState(false);
  const [flagPromotingDiversity, setFlagPromotingDiversity] = useState(false);
  const [flagMissionFunds, setFlagMissionFunds] = useState(false);
  const [flagTravel, setFlagTravel] = useState(false);
  const [flagDevelopment, setFlagDevelopment] = useState(false);
  const [careerLevels, setCareerLevels] = useState([]);
  const [componentGroups, setComponentGroups] = useState([]);
  const [componentGroupFilters, setComponentGroupFilters] = useState([]);
  const [page, setPage] = useState(0);
  const { pathname, search } = useLocation();
  const width = useWindowWidth();

  useEffect(() => {
    (async () => {
      try {
        const result = await getEndowmentList();
        if (result.status === 401) {
          navigate("/login?redirect=" + encodeURIComponent(pathname + search));
        } else if (result.status === 403) {
          setError("403 Error");
        } else if (result.status === 404) {
          setError("Sorry, the resource was not found. 404 Error.");
        } else {
          const programs = await result.json();
          setEndowmentsJson(programs);

          let componentGroupFilters = programs.reduce((acc, val) => {
            let { componentGroupType, componentGroupNameArray } = val;

            if (componentGroupType && componentGroupNameArray) {
              if (!acc.find((p) => p.code === componentGroupType)) {
                acc.push({
                  code: componentGroupType,
                  display: componentGroupType.replaceAll("_", " "),
                  items: [],
                });
              }
              let group = acc.find((p) => p.code === componentGroupType);
              let items = group.items;
              if (componentGroupNameArray && componentGroupNameArray.length) {
                componentGroupNameArray.forEach((cga) => {
                  if (!items.find((p) => p.code === cga)) {
                    const display = cga
                      .replaceAll("_", " ")
                      .replace(" Regional Chapter", "")
                      .replace(" Specialty Section", "");
                    items.push({
                      code: cga,
                      display,
                    });
                  }
                });
                items.sort((a, b) => a.display.localeCompare(b.display));
              }
            }
            return acc;
          }, []);
          setComponentGroupFilters(componentGroupFilters);
          setExpandedCGs(componentGroupFilters.map((c) => c.code));
        }
      } catch (e) {
        console.log("e", e);
        setError(
          "Unfortunately an error occurred while retrieving this resource"
        );
      }
      setIsLoading(false);
    })();
  }, []);

  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const sortByFn = (p1, p2) => {
    let normalDirection = sortByDirection === 1 ? 1 : -1;
    return (
      (p1.name > p2.name ? 1 : p1.name < p2.name ? -1 : 0) * normalDirection
    );
  };
  const swapSortByDirection = () => {
    setSortByDirection(sortByDirection * -1);
  };

  const filterFn = (program) => {
    if (searchText && searchText.trim()) {
      let searchTerm = searchText.trim().toLowerCase();
      if (
        (program.name || "").toLowerCase().indexOf(searchTerm) < 0 &&
        (program.description || "").toLowerCase().indexOf(searchTerm) < 0 &&
        (program.tags || "").toLowerCase().indexOf(searchTerm) < 0
      ) {
        return false;
      }
    }
    if (flagResearchExcellence) {
      if (!program.flagResearchExcellence) {
        return false;
      }
    }
    if (flagScientificMerit) {
      if (!program.flagScientificMerit) {
        return false;
      }
    }
    if (flagPromotingDiversity) {
      if (!program.flagPromotingDiversity) {
        return false;
      }
    }
    if (flagMissionFunds) {
      if (!program.flagMissionFunds) {
        return false;
      }
    }
    if (flagTravel) {
      if (!program.flagTravel) {
        return false;
      }
    }
    if (flagDevelopment) {
      if (!program.flagDevelopment) {
        return false;
      }
    }
    if (careerLevels && careerLevels.length) {
      if (
        !program.careerLevel ||
        careerLevels.indexOf(program.careerLevel) < 0
      ) {
        return false;
      }
    }
    if (componentGroups && componentGroups.length) {
      if (
        !program.componentGroupNameArray ||
        (program.componentGroupNameArray &&
          program.componentGroupNameArray.length === 0)
      ) {
        return false;
      } else {
        let found = false;
        program.componentGroupNameArray.forEach((cg) => {
          if (componentGroups.indexOf(cg) > -1) {
            found = true;
          }
        });
        return found;
      }
    }

    return true;
  };

  const toggleFilterBy = () => setFilterModal(!filterModal);

  let careerLevelFilter = [
    {
      display: "Early Level",
      code: "early",
    },
    {
      display: "Mid Level",
      code: "mid",
    },
    {
      display: "Senior Level",
      code: "senior",
    },
  ];

  const toggleCG = (code) => {
    const index = expandedCGs.indexOf(code);
    let newArray = expandedCGs.slice();
    if (index >= 0) {
      newArray.splice(index, 1);
    } else {
      newArray.push(code);
    }
    setExpandedCGs(newArray);
  };

  const toggleCareerLevels = (code) => {
    let newCareerLevels = [...careerLevels];
    if (newCareerLevels.indexOf(code) >= 0) {
      newCareerLevels.splice(newCareerLevels.indexOf(code), 1);
    } else {
      newCareerLevels.push(code);
    }
    setCareerLevels(newCareerLevels);
  };
  const toggleComponentGroups = (code) => {
    let newComponentGroups = [...componentGroups];
    if (newComponentGroups.indexOf(code) >= 0) {
      newComponentGroups.splice(newComponentGroups.indexOf(code), 1);
    } else {
      newComponentGroups.push(code);
    }
    setComponentGroups(newComponentGroups);
  };

  const toggleInfoModal = () => setInfoModalOpen(null);
  const filterSection = (key) => (
    <FilterSection>
      <FilterTitle>Filter by</FilterTitle>
      <FilterGroupTitle>Fund Purpose</FilterGroupTitle>
      <FilterItem>
        <FilterCheckbox
          type="checkbox"
          id={"excellence" + key}
          onChange={() => setFlagResearchExcellence(!flagResearchExcellence)}
          checked={flagResearchExcellence}
        />
        <FilterItemName htmlFor={"excellence" + key}>
          Achievement/Research Excellence
        </FilterItemName>
      </FilterItem>
      <FilterItem>
        <FilterCheckbox
          type="checkbox"
          id={"development" + key}
          onChange={() => setFlagDevelopment(!flagDevelopment)}
          checked={flagDevelopment}
        />
        <FilterItemName htmlFor={"development" + key}>
          Career Development
        </FilterItemName>
      </FilterItem>
      <FilterItem>
        <FilterCheckbox
          type="checkbox"
          id={"diversity" + key}
          onChange={() => setFlagPromotingDiversity(!flagPromotingDiversity)}
          checked={flagPromotingDiversity}
        />
        <FilterItemName htmlFor={"diversity" + key}>
          Promoting Diversity
        </FilterItemName>
      </FilterItem>
      <FilterItem>
        <FilterCheckbox
          type="checkbox"
          id={"mission" + key}
          onChange={() => setFlagMissionFunds(!flagMissionFunds)}
          checked={flagMissionFunds}
        />
        <FilterItemName htmlFor={"mission" + key}>SOT Missions</FilterItemName>
      </FilterItem>
      <FilterItem>
        <FilterCheckbox
          type="checkbox"
          id={"travel" + key}
          onChange={() => setFlagTravel(!flagTravel)}
          checked={flagTravel}
        />
        <FilterItemName htmlFor={"travel" + key}>
          Travel to Attend Scientific Meeting
        </FilterItemName>
      </FilterItem>
      <FilterGroupTitle>Career Level</FilterGroupTitle>
      <FilterGroup>
        {careerLevelFilter.map((filter) => (
          <FilterItem key={filter.code}>
            <FilterCheckbox
              type="checkbox"
              id={filter.code + key}
              onChange={() => toggleCareerLevels(filter.code)}
              value={careerLevels.indexOf(filter.code) >= 0}
            />
            <FilterItemName htmlFor={filter.code + key}>
              {filter.display}
            </FilterItemName>
          </FilterItem>
        ))}
      </FilterGroup>

      <FilterGroupTitle>Component Groups</FilterGroupTitle>
      {componentGroupFilters.map((cg) => {
        let expanded = expandedCGs.indexOf(cg.code) >= 0;
        return (
          <FilterGroup key={cg.code}>
            <ComponentGroupTitle onClick={() => toggleCG(cg.code)}>
              {cg.display}&nbsp;
              {expanded ? (
                <FontAwesomeIcon icon={faCaretDown} className="" />
              ) : (
                <FontAwesomeIcon icon={faCaretUp} className="" />
              )}
            </ComponentGroupTitle>
            {expanded
              ? cg.items.map((filter) => (
                  <FilterItem key={filter.code}>
                    <FilterCheckbox
                      type="checkbox"
                      id={filter.code + key}
                      onChange={() => toggleComponentGroups(filter.code)}
                      checked={componentGroups.indexOf(filter.code) >= 0}
                    />
                    <FilterItemName htmlFor={filter.code + key}>
                      {filter.display}
                    </FilterItemName>
                  </FilterItem>
                ))
              : null}
          </FilterGroup>
        );
      })}
    </FilterSection>
  );

  let selectedEndowment =
    endowmentsJson &&
    endowmentsJson.find((endowment) => endowment.id === infoModalOpen);
  const { link } = selectedEndowment || {};

  let unpaginatedEventList = endowmentsJson
    ? endowmentsJson.filter(filterFn).sort(sortByFn)
    : null;
  let moreThanOnePage =
    unpaginatedEventList && unpaginatedEventList.length > PerPage
      ? true
      : false;
  let pageCount = unpaginatedEventList
    ? Math.ceil(unpaginatedEventList.length / PerPage)
    : 0;
  let canPageLeft = unpaginatedEventList && page > 0 ? true : false;
  let canPageRight =
    unpaginatedEventList && pageCount - 1 > page ? true : false;
  let pageArray = [1];
  if (page > 1) {
    if (page > 2) {
      pageArray.push(null);
    }
    pageArray.push(page - 1 + 1);
  }
  if (page > 0) {
    pageArray.push(page + 1);
  }
  if (pageCount - 1 > page) {
    pageArray.push(page + 2);
  }
  if (pageCount > page + 2) {
    if (pageCount > page + 3) {
      pageArray.push(null);
    }
    pageArray.push(pageCount);
  }

  return (
    <SectionContent>
      <Modal
        isOpen={!!infoModalOpen}
        onRequestClose={toggleInfoModal}
        contentLabel="Info Modal"
        style={
          width >= 992
            ? {
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                },
                overlay: {
                  zIndex: 100,
                },
              }
            : {
                content: {
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  zIndex: 100,
                },
                overlay: {
                  zIndex: 100,
                },
              }
        }
      >
        <CloseButton onClick={toggleInfoModal}>
          <FontAwesomeIcon icon={faClose} className="" />
        </CloseButton>
        <ModalTopHR />
        <ModalSection>
          <EndModalLeft>
            <EndModalImg
              src={selectedEndowment && selectedEndowment.imageUrl}
              alt={selectedEndowment && selectedEndowment.name}
            />
          </EndModalLeft>
          <EndModalRight>
            <ModalTitle>
              {selectedEndowment && selectedEndowment.name}
            </ModalTitle>
            <ModalDescription>
              {selectedEndowment && selectedEndowment.fullDescription}
            </ModalDescription>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <ModalLink>Donate Now</ModalLink>
            </a>
          </EndModalRight>
        </ModalSection>
      </Modal>
      <Modal
        isOpen={filterModal}
        onRequestClose={toggleFilterBy}
        contentLabel="Filter Modal"
        style={{
          content: {
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 100,
          },
          overlay: {
            zIndex: 100,
          },
        }}
      >
        <CloseButtonFixed onClick={toggleFilterBy}>
          <FontAwesomeIcon icon={faClose} className="" />
        </CloseButtonFixed>
        {filterSection("modal")}
      </Modal>

      <TitleSection>
        <Title>Search the Funds</Title>
        <Description1>
          Use this search page to identify funds that support causes and
          individuals aligned with your interests.
        </Description1>
        <Description2>
          Upon identifying the fund(s) you want to support, use the{" "}
          <a
            href="https://www.toxicology.org/endowment/contribute/EndowmentOnline.asp"
            target="_blank"
          >
            online giving system
          </a>{" "}
          or{" "}
          <a
            href="https://www.toxicology.org/endowment/docs/Endowment_Donor_form.pdf"
            target="_blank"
          >
            download the Donation form{" "}
            <FontAwesomeIcon
              icon={faFilePdf}
              className="fa-regular fa-file-pdf"
            />
          </a>{" "}
          to make a gift—be sure to clearly identify the fund(s) you are
          supporting.
        </Description2>
      </TitleSection>
      {isLoading ? (
        <Panel>
          <Loading>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          </Loading>
        </Panel>
      ) : error ? (
        <div>
          <span
            className="alert alert-danger"
            role="alert"
            dangerouslySetInnerHTML={{ __html: error }}
          />
        </div>
      ) : (
        <Panel>
          <SearchBoxWrapper>
            <SearchBoxContainer>
              <SearchBox
                value={searchText}
                onChange={onSearchTextChange}
                placeholder="Search"
              />
              <SearchButton>
                <FontAwesomeIcon icon={faSearch} className="fa-search" />
              </SearchButton>
            </SearchBoxContainer>
          </SearchBoxWrapper>
          <MainArea>
            <LeftSection>{filterSection("nonmodal")}</LeftSection>
            <MainSection>
              <ControlsContainer>
                <FilterByContainer onClick={toggleFilterBy}>
                  <FontAwesomeIcon icon={faSliders} className="fa-search" />
                  <Bold>Filter by</Bold>
                </FilterByContainer>
                <SortByContainer onClick={swapSortByDirection}>
                  <Bold>Sort by</Bold>&nbsp;|&nbsp;{sortBy}&nbsp;
                  {sortByDirection === 1 ? (
                    <FontAwesomeIcon icon={faCaretDown} className="fa-search" />
                  ) : (
                    <FontAwesomeIcon icon={faCaretUp} className="fa-search" />
                  )}
                </SortByContainer>
              </ControlsContainer>
              <EndowmentListContainer>
                {unpaginatedEventList
                  ? unpaginatedEventList
                      .slice(page * PerPage, (page + 1) * PerPage)
                      .map((endowment) => {
                        return (
                          <EndowmentWrapper key={endowment.id}>
                            <EndowmentContainer>
                              <TitleAndSummaryWrapper>
                                <EndowmentHeader>
                                  <ProgramTitle>{endowment.name}</ProgramTitle>
                                </EndowmentHeader>
                                <ProgramSummary>
                                  {endowment.description}
                                </ProgramSummary>
                              </TitleAndSummaryWrapper>
                              <ReadMore
                                onClick={() => setInfoModalOpen(endowment.id)}
                              >
                                Read more&nbsp;
                                <FontAwesomeIcon
                                  icon={faAnglesRight}
                                  className="fa-angles-right"
                                />
                              </ReadMore>
                            </EndowmentContainer>
                          </EndowmentWrapper>
                        );
                      })
                  : null}
              </EndowmentListContainer>
              <PaginationSection>
                {moreThanOnePage ? (
                  <LeftRightButton
                    disabled={!canPageLeft}
                    onClick={() => setPage(page - 1)}
                  >
                    &lt;
                  </LeftRightButton>
                ) : null}
                {pageArray.map((pageItem, index) => {
                  const Button =
                    pageItem && pageItem === page + 1
                      ? PageButtonSelected
                      : PageButton;
                  return pageItem ? (
                    <Button onClick={() => setPage(pageItem - 1)} key={index}>
                      {pageItem}
                    </Button>
                  ) : (
                    <PageSpacer key={index}>...</PageSpacer>
                  );
                })}
                {moreThanOnePage ? (
                  <LeftRightButton
                    disabled={!canPageRight}
                    onClick={() => setPage(page + 1)}
                  >
                    &gt;
                  </LeftRightButton>
                ) : null}
              </PaginationSection>
            </MainSection>
          </MainArea>
        </Panel>
      )}
    </SectionContent>
  );
}

export default EndowmentPage;

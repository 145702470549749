import styled from 'styled-components';
import React, {useState, useEffect} from "react";
import {blue, red} from "../../styles";
import {
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useNavigate, useLocation, useParams} from "react-router-dom";
import Course from "./Course";
import {getCourse} from "../../api";

const SectionContent = styled.div`
`;
const Panel = styled.div`
  text-align: left;
  h2 {
    color: ${blue} !important;
    font-weight: 600;
  }
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;

function CoursePage({linkPrefix = 'https://www.toxicology.org'}) {
    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState([]);
    const [title, setTitle] = useState([]);
    const [error, setError] = useState();
    const navigate = useNavigate();
    const {
        pathname,
        search,
    } = useLocation();
    let { course } = useParams();

    useEffect(() => {(async () => {
        try {
            const result = await getCourse(course);
            if ( result.status === 401 ) {
                navigate('/login?redirect=' + encodeURIComponent(pathname + search));
            } else if ( result.status === 403 ) {
                setError("If you have not registered for this course and would like access to the course materials, please register on the <a href='https://www.toxicology.org/education/ce/onlineCourses.asp'>CEd-Tox page</a>.");
            } else if ( result.status === 404 ) {
                setError("Sorry, the resource was not found.");
            } else {
                const { content, title } = await result.json();
                setContent( content );
                setTitle( title );
            }
        } catch (e) {
            console.log('e', e);
            setError("Unfortunately an error occurred while retrieving this resource");
        }
        setIsLoading(false);
    })()}, []);


    return (
        <SectionContent>
            {
                isLoading ? (
                    <Panel>
                        <Loading>
                            <FontAwesomeIcon icon={faSpinner} className="fa-spin"/>
                        </Loading>
                    </Panel>
                ) : error ? (
                    <div>
                        <span className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{__html: error}}/>
                    </div>
                ) : (
                    <Panel>
                        <Course title={title} course={content}/>
                    </Panel>
                )
            }
        </SectionContent>
    );
}

export default CoursePage;

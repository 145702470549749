import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";
import {
  blue,
  red,
  standardP,
  standardH3,
  grayBoxes,
  orange,
} from "../../styles";
import {
  faSpinner,
  faSearch,
  faAnglesRight,
  faSliders,
  faClose,
  faCaretDown,
  faCaretUp,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import { getProgramList } from "../../api";
import Modal from "react-modal";
import Select from "react-dropdown-select";

const fadeInAnimation = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const SectionContent = styled.div``;
const Panel = styled.div`
  text-align: left;
  h2 {
    color: ${blue} !important;
    font-weight: 600;
  }
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;
const TitleSection = styled.div`
  text-align: center;
`;
const Title = styled.h2`
  color: ${blue};
  font-weight: 600;
  font-size: 28px;
`;
const Description1 = styled.p`
  color: ${standardH3};
  max-width: 700px;
  display: inline-block;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 300;
`;
const Description2 = styled.p`
  color: ${standardP};
  max-width: 950px;
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  line-height: 1.5;
  font-weight: 300;
`;
const InstructionBox = styled.div`
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  margin-bottom: 5px;
`;
const ModalLink = styled.span`
  color: #0645ad;
  text-decoration: underline;
  cursor: pointer;
`;
const SearchBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const SearchBoxContainer = styled.div`
  margin: 15px 25px;
  display: flex;
  max-width: 350px;
  width: 100%;
`;
const borderRadius = "5px";
const SearchBox = styled.input`
  border-top-left-radius: ${borderRadius};
  border-bottom-left-radius: ${borderRadius};
  font-weight: normal;
  color: black;
  margin: 0;
  padding: 8px 16px;
  width: 100%;
  border: 1px solid #d0d0d0;
  border-right: 0px solid transparent;
`;
const SearchButton = styled.button`
  border-top-right-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
  background-color: ${orange};
  border: 1px solid ${orange};
  color: white;
  padding: 0px 20px;
`;
const MainArea = styled.div`
  display: flex;
  flex-direction: row;
`;
const LeftSection = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
    width: 25%;
  }
`;
const MainSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const FilterTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  @media (max-width: 992px) {
    max-width: 92%;
    align-items: flex-start;
  }
`;
const FilterTitle = styled.h3`
  color: ${blue};
  margin-bottom: 18px;
`;
const ClearAnchor = styled.a`
  color: black;
  font-size: 14px;
  text-align: right;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 10px;
`;
const SortListItem = styled.span`
  border: 0px solid transparent;
  background-color: ${(props) => (props.isChosen ? "#003767" : "transparent")};
  align-self: flex-end;
  justify-content: flex-start;
  display: flex;
  width: 100%;
  color: ${(props) => (props.isChosen ? "white" : standardP)};
  font-size: 15px;
  padding: 0px 10px;
  align-items: center;
  :hover {
    background: #f2f2f2;
  }
`;
const SortByContainer = styled.div`
  min-width: 100px;
  display: flex;
  align-items: center;
  max-width: 200px;
  max-height: 25px;
  white-space: nowrap;
`;
const StyledSelect = styled(Select)`
  border: transparent !important;
  .react-dropdown-select-input {
    color: ${standardP};
    font-size: 15px;
    min-width: 80px;
  }
  .react-dropdown-select-content {
    max-height: 25px;
    width: 100px;
    width: 135px;
  }
`;
const Bold = styled.span`
  font-weight: bold;
  margin-left: 3px;
  line-height: 2;
`;
const ProgramContainer = styled.div`
  background-color: ${grayBoxes};
  width: 100%;
  margin: 12px 0;
  padding: 25px 25px;
  @media (min-width: 768px) {
    margin: 12px 10px 12px 0;
  }
`;
const ProgramHeader = styled.div`
  display: flex;
  flex-direction: row;
`;
const ProgramDate = styled.div`
  background-color: ${blue};
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
  margin-right: 15px;
  min-height: 73px;
  max-height: 73px;
`;
const DeadlineMonth = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: normal;
`;
const DeadlineYear = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: normal;
`;
const DeadlineText = styled.div`
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: normal;
`;
const ProgramTitle = styled.h3`
  flex: 1;
  margin: auto;
`;
const ProgramSummary = styled.div`
  width: 100%;
  margin: 12px 0;
`;
const ReadMore = styled.a`
  float: right;
  border-radius: ${borderRadius};
  background-color: ${orange};
  border: 1px solid ${orange};
  color: white;
  padding: 4px 20px;
  font-size: 16px;
  text-decoration: none;
  &:hover {
    color: white;
  }
`;

const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
`;
const FilterGroupTitle = styled.p`
  font-weight: bold;
  margin-bottom: 10px;
  color: ${standardP};
  font-size: 16px;
`;
const FilterGroup = styled.div`
  margin-left: 12px;
  margin-bottom: 20px;
  padding-right: 10px;
`;
const ComponentGroupTitle = styled.p`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
`;
const FilterItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
  animation-name: ${fadeInAnimation};
  animation-duration: 0.3s;
  @media (min-width: 768px) {
    margin: 0px 0px 3px;
  }
`;
const FilterCheckbox = styled.input`
  margin-top: 6px;
  margin-right: 12px;
  align-self: flex-start;
`;
const FilterItemName = styled.label`
  margin-bottom: 0;
  color: ${standardP};
  font-size: 15px;
`;
const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (min-width: 992px) {
    justify-content: flex-end;
  }
`;
const FilterByContainer = styled.button`
  border-radius: ${borderRadius};
  border: 2px solid ${blue};
  color: ${blue};
  background-color: transparent;
  @media (min-width: 992px) {
    display: none;
  }
`;
const CloseButtonFixed = styled.button`
  border: 0px solid transparent;
  position: fixed;
  top: 0;
  right: 0;
  padding: 18px 25px;
  background-color: white;
`;
const CloseButton = styled.button`
  border: 0px solid transparent;
  position: absolute;
  top: 0;
  right: 0;
  padding: 18px 25px;
  background-color: white;
`;
const AwardTypeButton = styled.button`
  display: none;
  background-color: transparent;
  border: 0px solid transparent;
  padding: 10px 10px 10px 0;
  margin-left: 6px;
  color: red;

  @media (min-width: 768px) {
    display: inline-block;
  }
`;
const ModalSection = styled.div``;
const ModalSubHeading = styled.h3`
  margin: 24px 0 17px;
  font-size: 24px;
`;
const ModalDescription = styled.p`
  font-size: 14px;
`;
const InstructionsListing = styled.ul``;
const InstructionListItem = styled.li`
  font-family: Roboto, sans-serif;
`;
const Spacer = styled.div`
  height: 100px;
`;
const PaginationSection = styled.div`
  align-self: center;
`;
const PageButton = styled.button`
  background-color: ${grayBoxes};
  border-radius: ${borderRadius};
  border: 0px solid transparent;
  font-weight: bold;
  margin: 10px 5px;
  padding: 2px 12px;
`;
const PageButtonSelected = styled.button`
  background-color: ${blue};
  color: white;
  border-radius: ${borderRadius};
  border: 0px solid transparent;
  font-weight: bold;
  margin: 10px 5px;
  padding: 2px 12px;
`;
const PageSpacer = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0px;
  padding: 2px 6px;
`;
const LeftRightButton = styled.button`
  background-color: transparent;
  border: 0px solid transparent;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0px;
  padding: 2px 12px;
`;
const PerPage = 10;
let smApplyUrl = "https://sotapply.toxicology.org";
function EventPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [newProgramsJson, setNewProgramsJson] = useState([]);
  const [oldProgramsJson, setOldProgramsJson] = useState([]);
  const [componentGroups, setComponentGroups] = useState({});
  const [searchText, setSearchText] = useState();
  const [chosenSorting, setChosenSorting] = useState("dd1");
  const [error, setError] = useState();
  const [filterModal, setFilterModal] = useState(false);
  const [instructionModal, setInstructionModal] = useState(false);
  const [expandedCGs, setExpandedCGs] = useState([]);
  const [filteredCGs, setFilteredCGs] = useState([]);
  const [page, setPage] = useState(0);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const navigate = useNavigate();
  const [awardInfoModalOpen, setAwardInfoModalOpen] = useState(false);
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const today = Date.now();
  const sortToggles = [
    { label: "Deadline Soonest", value: "dd1" },
    { label: "Deadline Latest", value: "dd-1" },
    { label: "A-Z", value: "az1" },
    { label: "Z-A", value: "az-1x" },
  ];

  useEffect(() => {
    (async () => {
      try {
        const result = await getProgramList();
        if (result.status === 401) {
          navigate("/login?redirect=" + encodeURIComponent(pathname + search));
        } else if (result.status === 403) {
          setError(
            "If you have not registered for this course and would like access to the course materials, please register on the <a href='https://www.toxicology.org/events/am/AM2023/registration.asp'>Annual Meeting Registration page</a>."
          );
        } else if (result.status === 404) {
          setError("Sorry, the resource was not found.");
        } else {
          const programs = result.json;
          let filteredPrograms = programs.filter((prog) => {
            if (prog && prog.custom_fields) {
              let showField = prog.custom_fields.find(
                (field) => field.id === 145515
              );
              if (
                showField &&
                showField.value &&
                showField.value.toLowerCase() === "yes"
              ) {
                const filteredCategories = queryParams.get("categories");
                if (filteredCategories && filteredCategories.length) {
                  setFilteredCategories(
                    filteredCategories.split("_").map((p) => parseInt(p))
                  );
                }
                const filteredCGs = queryParams.get("cgs");
                if (filteredCGs && filteredCGs.length) {
                  setFilteredCGs(filteredCGs.split("_"));
                }
                return true;
              }
            }
            return false;
          });

          // Sort old and new programs
          filteredPrograms.forEach((prog) => {
            const progDate = new Date(prog.close_datetime).getTime();

            if (progDate >= today) {
              setNewProgramsJson((prevProg) => [...prevProg, prog]);
            } else {
              setOldProgramsJson((prevProg) => [...prevProg, prog]);
            }
          });
          let componentGroups = filteredPrograms.reduce((acc, prog) => {
            if (prog && prog.custom_fields) {
              let typeField = prog.custom_fields.find(
                (field) => field.id === 145442
              );
              let groupField = prog.custom_fields.find(
                (field) => field.id === 145168
              );
              if (
                typeField &&
                typeField.value &&
                groupField &&
                groupField.value
              ) {
                if (!acc[typeField.value]) {
                  acc[typeField.value] = [];
                }
                if (acc[typeField.value].indexOf(groupField.value) < 0) {
                  acc[typeField.value].push(groupField.value);
                }
              }
            }
            return acc;
          }, {});
          setComponentGroups(componentGroups);
          setExpandedCGs(Object.keys(componentGroups));
        }
      } catch (e) {
        console.log("e", e);
        setError(
          "Unfortunately an error occurred while retrieving this resource"
        );
      }
      setIsLoading(false);
    })();
  }, []);

  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
    setPage(0);
  };

  const sortByFn = (p1, p2) => {
    let normalDirection = !chosenSorting.includes("-1") ? 1 : -1;
    if (chosenSorting.includes("dd")) {
      let p1Time = new Date(p1.close_datetime);
      let p2Time = new Date(p2.close_datetime);

      const aTime = p1Time.getTime();
      const bTime = p2Time.getTime();

      if (aTime > today && bTime > today) {
        return (aTime - bTime) * normalDirection;
      } else if (aTime <= today && bTime <= today) {
        return (bTime - aTime) * normalDirection;
      } else if (aTime > today) {
        return -1;
      } else {
        return 1;
      }
    } else {
      return (
        (p1.name > p2.name ? 1 : p1.name < p2.name ? -1 : 0) * normalDirection
      );
    }
  };
  const swapSortByDirection = (selection) => {
    setChosenSorting(selection[0].value);
  };

  const filterFn = (program) => {
    if (searchText && searchText.trim()) {
      let searchTerm = searchText.trim().toLowerCase();
      if (
        (program.name || "").toLowerCase().indexOf(searchTerm) < 0 &&
        (program.summary || "").toLowerCase().indexOf(searchTerm) < 0 &&
        (program.description || "").toLowerCase().indexOf(searchTerm) < 0
      ) {
        let typeField = program.custom_fields.find(
          (field) => field.id === 145442
        );
        let groupField = program.custom_fields.find(
          (field) => field.id === 145168
        );
        let groupAbrField = program.custom_fields.find(
          (field) => field.id === 145440
        );
        let metatagsField = program.custom_fields.find(
          (field) => field.id === 145121
        );

        let hit = false;
        if (
          typeField &&
          typeField.value &&
          typeField.value.toLowerCase().indexOf(searchTerm) >= 0
        ) {
          hit = true;
        }
        if (
          groupField &&
          groupField.value &&
          groupField.value.toLowerCase().indexOf(searchTerm) >= 0
        ) {
          hit = true;
        }
        if (
          groupAbrField &&
          groupAbrField.value &&
          groupAbrField.value.toLowerCase().indexOf(searchTerm) >= 0
        ) {
          hit = true;
        }
        if (
          metatagsField &&
          metatagsField.value &&
          metatagsField.value.toLowerCase().indexOf(searchTerm) >= 0
        ) {
          hit = true;
        }

        if (!hit) {
          return false;
        }
      }
    }
    if (filteredCGs && filteredCGs.length) {
      if (!program.custom_fields) {
        return false;
      }
      let groupField = program.custom_fields.find(
        (field) => field.id === 145168
      );
      if (
        !groupField ||
        !groupField.value ||
        filteredCGs.indexOf(groupField.value) < 0
      ) {
        return false;
      }
    }

    if (filteredCategories && filteredCategories.length) {
      if (!program.categories) {
        return false;
      }
      for (let filteredCategory of filteredCategories) {
        if (
          !program.categories.find((field) => field.id === filteredCategory)
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const toggleInstructionModal = () => setInstructionModal(!instructionModal);

  const toggleFilterBy = () => setFilterModal(!filterModal);

  let awardTypeFilters = [
    {
      display: "SOT Awards",
      code: 16762,
    },
    {
      display: "SOT Endowment Awards",
      code: 16867,
    },
    {
      display: "Supported Awards",
      code: 16868,
    },
  ];
  let careerDesignationFilters = [
    {
      display: "All Scientists",
      code: 16869,
    },
    {
      display: "Educators",
      code: 16870,
    },
    {
      display: "Graduate Students",
      code: 16871,
    },
    {
      display: "Postdoctoral",
      code: 16872,
    },
    {
      display: "Undergraduate Students",
      code: 16873,
    },
  ];

  const toggleCG = (code) => {
    const index = expandedCGs.indexOf(code);
    let newArray = expandedCGs.slice();
    if (index >= 0) {
      newArray.splice(index, 1);
    } else {
      newArray.push(code);
    }
    setExpandedCGs(newArray);
  };
  const toggleComponentGroup = (code) => {
    const index = filteredCGs.indexOf(code);
    let newArray = filteredCGs.slice();
    if (index >= 0) {
      newArray.splice(index, 1);
    } else {
      newArray.push(code);
    }
    setFilteredCGs(newArray);
    setPage(0);
    setQueryParams(newArray, "cgs");
  };
  const toggleCategory = (code) => {
    const index = filteredCategories.indexOf(code);
    let newArray = filteredCategories.slice();
    if (index >= 0) {
      newArray.splice(index, 1);
    } else {
      newArray.push(code);
    }
    setFilteredCategories(newArray);
    setPage(0);
    setQueryParams(newArray, "categories");
  };
  const setQueryParams = (array, type) => {
    if (array.length) {
      const stringed = array.join("_");
      queryParams.set(type, stringed);
    } else {
      queryParams.delete(type);
    }
    handleNav();
  };
  const removeFilters = () => {
    if (filteredCGs.length) {
      queryParams.delete("cgs");
    }
    if (filteredCategories.length) {
      queryParams.delete("categories");
    }
    setFilteredCategories([]);
    setFilteredCGs([]);
    handleNav();
  };
  const handleNav = () => {
    const newSearch = `?${queryParams.toString()}`;
    navigate({ search: newSearch });
  };
  const toggleAwardInfoModal = () => setAwardInfoModalOpen(!awardInfoModalOpen);
  const filterSection = (key) => (
    <FilterSection>
      <FilterTitleWrapper>
        <FilterTitle>Filter by</FilterTitle>{" "}
        <ClearAnchor onClick={removeFilters}>Clear All</ClearAnchor>
      </FilterTitleWrapper>
      <FilterGroupTitle>Career Designation</FilterGroupTitle>
      <FilterGroup>
        {careerDesignationFilters.map((filter) => (
          <FilterItem key={filter.code}>
            <FilterCheckbox
              type="checkbox"
              id={filter.code + key}
              onChange={() => toggleCategory(filter.code)}
              checked={filteredCategories.indexOf(filter.code) >= 0}
            />
            <FilterItemName htmlFor={filter.code + key}>
              {filter.display}
            </FilterItemName>
          </FilterItem>
        ))}
      </FilterGroup>
      <FilterGroupTitle>
        Award Type&nbsp;
        <AwardTypeButton onClick={toggleAwardInfoModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="currentColor"
            className="bi bi-info-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </AwardTypeButton>
      </FilterGroupTitle>
      <FilterGroup>
        {awardTypeFilters.map((filter) => (
          <FilterItem key={filter.code}>
            <FilterCheckbox
              type="checkbox"
              id={filter.code + key}
              onChange={() => toggleCategory(filter.code)}
              checked={filteredCategories.indexOf(filter.code) >= 0}
            />
            <FilterItemName htmlFor={filter.code + key}>
              {filter.display}
            </FilterItemName>
          </FilterItem>
        ))}
      </FilterGroup>
      {Object.keys(componentGroups).length ? (
        <FilterGroupTitle>Component Groups</FilterGroupTitle>
      ) : null}
      {Object.keys(componentGroups)
        .sort((cg1, cg2) => (cg1 > cg2 ? 1 : cg1 < cg2 ? -1 : 0))
        .map((cg) => {
          let expanded = expandedCGs.indexOf(cg) >= 0;
          let code = cg.replace(/[^\w]/g, "");
          return (
            <FilterGroup key={code}>
              <ComponentGroupTitle onClick={() => toggleCG(cg)}>
                {cg}&nbsp;
                {expanded ? (
                  <FontAwesomeIcon
                    size="xs"
                    icon={faChevronDown}
                    className=""
                  />
                ) : (
                  <FontAwesomeIcon size="xs" icon={faChevronUp} className="" />
                )}
              </ComponentGroupTitle>
              {componentGroups[cg]
                .sort((cg1, cg2) => (cg1 > cg2 ? 1 : cg1 < cg2 ? -1 : 0))
                .map((cgName) => {
                  let cgNameCode = cgName.replace(/[^\w]/g, "");
                  return expanded ? (
                    <FilterItem key={cgNameCode}>
                      <FilterCheckbox
                        type="checkbox"
                        id={cgNameCode + key}
                        onChange={() => toggleComponentGroup(cgName)}
                        checked={filteredCGs.indexOf(cgName) >= 0}
                      />
                      <FilterItemName htmlFor={cgNameCode + key}>
                        {cgName}
                      </FilterItemName>
                    </FilterItem>
                  ) : null;
                })}
            </FilterGroup>
          );
        })}
    </FilterSection>
  );

  const newProgsFilteredAndSorted = newProgramsJson
    .filter(filterFn)
    .sort(sortByFn);
  const oldProgsFilteredAndSorted = oldProgramsJson
    .filter(filterFn)
    .sort(sortByFn);
  let unpaginatedEventList = [
    ...newProgsFilteredAndSorted,
    ...oldProgsFilteredAndSorted,
  ];
  let moreThanOnePage =
    unpaginatedEventList && unpaginatedEventList.length > PerPage
      ? true
      : false;
  let pageCount = unpaginatedEventList
    ? Math.ceil(unpaginatedEventList.length / PerPage)
    : 0;
  let canPageLeft = unpaginatedEventList && page > 0 ? true : false;
  let canPageRight =
    unpaginatedEventList && pageCount - 1 > page ? true : false;
  let pageArray = [1];
  if (page > 1) {
    if (page > 2) {
      pageArray.push(null);
    }
    pageArray.push(page - 1 + 1);
  }
  if (page > 0) {
    pageArray.push(page + 1);
  }
  if (pageCount - 1 > page) {
    pageArray.push(page + 2);
  }
  if (pageCount > page + 2) {
    if (pageCount > page + 3) {
      pageArray.push(null);
    }
    pageArray.push(pageCount);
  }

  const customContentRenderer = ({ props, state }) => (
    <SortListItem style={{ minWidth: "80px", padding: "0px 2px" }}>
      <span>
        {sortToggles.find((t) => t.value === chosenSorting).label}&nbsp;
      </span>
      {["dd1", "az1"].includes(chosenSorting) ? (
        <FontAwesomeIcon icon={faCaretUp} className="fa-search" />
      ) : (
        <FontAwesomeIcon icon={faCaretDown} className="fa-search" />
      )}
    </SortListItem>
  );

  return (
    <SectionContent>
      <Modal
        isOpen={awardInfoModalOpen}
        onRequestClose={setAwardInfoModalOpen}
        contentLabel="Info Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 1000,
          },
          overlay: {
            backgroundColor: "rgba(80, 80, 80, 67%)",
          },
        }}
      >
        <CloseButton onClick={toggleAwardInfoModal}>
          <FontAwesomeIcon icon={faClose} className="" />
        </CloseButton>
        <ModalSection>
          <Title>Award Type Definitions</Title>
          <ModalSubHeading>SOT Awards</ModalSubHeading>
          <ModalDescription>
            The SOT Awards Designation indicates those awards in which the
            recipient is selected by the SOT Awards Committee or other SOT
            Committee. A majority of those awards have an October 9 deadline,
            and the recipient of those awards are honored during the Awards
            Ceremony during the SOT Annual Meeting and ToxExpo.
          </ModalDescription>
          <ModalSubHeading>SOT Endowment Awards</ModalSubHeading>
          <ModalDescription>
            SOT Endowment Awards are usually bestowed by the SOT Component
            Groups and have varying deadlines. They are supported through
            donations to and investments by the SOT Endowment Fund and its
            individual funds. SOT Component Groups also bestow awards not
            supported through the Endowment Fund.
          </ModalDescription>
          <ModalSubHeading>Supported Awards</ModalSubHeading>
          <ModalDescription>
            Supported Awards are sponsored by other companies and organizations
            with the SOT Awards Committee selecting the recipients and SOT staff
            providing administrative support. These awards often fund research
            or training opportunities.
          </ModalDescription>
        </ModalSection>
      </Modal>
      <Modal
        isOpen={filterModal}
        onRequestClose={toggleFilterBy}
        contentLabel="Filter Modal"
        style={{
          content: {
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 100,
          },
          overlay: {
            zIndex: 100,
          },
        }}
      >
        <CloseButtonFixed onClick={toggleFilterBy}>
          <FontAwesomeIcon icon={faClose} className="" />
        </CloseButtonFixed>
        {filterSection("modal")}
      </Modal>
      <Modal
        isOpen={instructionModal}
        onRequestClose={setInstructionModal}
        contentLabel="Filter Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 1000,
          },
          overlay: {
            backgroundColor: "rgba(80, 80, 80, 67%)",
          },
        }}
      >
        <CloseButton onClick={toggleInstructionModal}>
          <FontAwesomeIcon icon={faClose} className="" />
        </CloseButton>
        <ModalSection>
          <Title>Instructions:</Title>
          <InstructionsListing>
            <InstructionListItem>
              If you have previously created an account through Survey Monkey
              Apply, select the log-in button and enter the same email and
              password that are associated with your Apply account.
            </InstructionListItem>
            <InstructionListItem>
              If you do not have an Apply account but you are an SOT member, an
              Apply account has been created for you using the email associated
              with your SOT membership. To access this account, select the
              log-in button and then the “Forgot your password?” option. Be sure
              to use the same email address that you use for your SOT
              credentials.
            </InstructionListItem>
            <InstructionListItem>
              If you do not have an Apply account and are not an SOT member,
              select the register button to create an account.
            </InstructionListItem>
          </InstructionsListing>
        </ModalSection>
      </Modal>

      <TitleSection>
        <Title>Search for Awards</Title>
        <Description1>
          Each year, the Society recognizes scientists who are making a positive
          impact on the field of toxicology through the SOT Awards program.
        </Description1>
        <Description2>
          SOT Awards are distributed by a variety of groups within and connected
          to SOT and often consist of a stipend or financial support for
          research. For nomination and application instructions, visit the
          individual award pages by using the search or filter options on this
          web page.
        </Description2>
        <InstructionBox>
          Please read{" "}
          <ModalLink onClick={toggleInstructionModal}>
            these instructions
          </ModalLink>
          , which provide information on how to create or modify your SOT Apply
          account, before opening a specific award application/description page.
        </InstructionBox>
      </TitleSection>
      {isLoading ? (
        <Panel>
          <Loading>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          </Loading>
        </Panel>
      ) : error ? (
        <div>
          <span
            className="alert alert-danger"
            role="alert"
            dangerouslySetInnerHTML={{ __html: error }}
          />
        </div>
      ) : (
        <Panel>
          <SearchBoxWrapper>
            <SearchBoxContainer>
              <SearchBox
                value={searchText}
                onChange={onSearchTextChange}
                placeholder="Enter an Award Name or Keyword"
              />
              <SearchButton>
                <FontAwesomeIcon icon={faSearch} className="fa-search" />
              </SearchButton>
            </SearchBoxContainer>
          </SearchBoxWrapper>
          <MainArea>
            <LeftSection>{filterSection("nonmodal")}</LeftSection>
            <MainSection>
              <ControlsContainer>
                <FilterByContainer onClick={toggleFilterBy}>
                  <FontAwesomeIcon icon={faSliders} className="fa-search" />
                  <Bold>Filter by</Bold>
                </FilterByContainer>
                <SortByContainer>
                  <Bold>Sort by</Bold>&nbsp; |
                  <StyledSelect
                    options={sortToggles}
                    values={[]}
                    contentRenderer={customContentRenderer}
                    searchable={false}
                    dropdownHandle={false}
                    placeholder="Deadline"
                    itemRenderer={({ item, methods }) => (
                      <SortListItem
                        isChosen={chosenSorting === item.value}
                        onClick={() => methods.addItem(item)}
                        key={item.label + "" + item.value}
                      >
                        <span>{item.label}</span>
                      </SortListItem>
                    )}
                    onChange={(val) => swapSortByDirection(val)}
                  />
                </SortByContainer>
              </ControlsContainer>
              {unpaginatedEventList
                ? unpaginatedEventList
                    .slice(page * PerPage, (page + 1) * PerPage)
                    .map((program, idx) => {
                      let deadline = new Date(program.close_datetime);
                      let options = { month: "short", timeZone: "EST" };
                      let deadlineMonth = new Intl.DateTimeFormat(
                        "en-US",
                        options
                      ).format(deadline);
                      options = { day: "numeric", timeZone: "EST" };
                      let deadlineDay = new Intl.DateTimeFormat(
                        "en-US",
                        options
                      ).format(deadline);
                      options = { year: "numeric", timeZone: "EST" };
                      let deadlineYear = new Intl.DateTimeFormat(
                        "en-US",
                        options
                      ).format(deadline);
                      return (
                        <>
                          {deadline < today &&
                          idx === newProgsFilteredAndSorted.length ? (
                            <>
                              <br></br>
                              <Title key="Past-awards">Past Awards</Title>
                            </>
                          ) : null}
                          <ProgramContainer
                            key={
                              program.id +
                              "_" +
                              program.close_datetime +
                              "_" +
                              idx
                            }
                          >
                            <ProgramHeader>
                              <ProgramDate>
                                <DeadlineMonth>
                                  {deadlineMonth}&nbsp;{deadlineDay}
                                </DeadlineMonth>
                                <DeadlineYear>{deadlineYear}</DeadlineYear>
                                <DeadlineText>DEADLINE</DeadlineText>
                              </ProgramDate>
                              <ProgramTitle>{program.name}</ProgramTitle>
                            </ProgramHeader>
                            <ProgramSummary>{program.summary}</ProgramSummary>
                            <ReadMore
                              target="_blank"
                              href={`${smApplyUrl}/prog/${program.listing_url}`}
                            >
                              Read more&nbsp;
                              <FontAwesomeIcon
                                size="2xs"
                                icon={faAnglesRight}
                                className="fa-angles-right"
                              />
                            </ReadMore>
                          </ProgramContainer>
                        </>
                      );
                    })
                : null}
              <PaginationSection>
                {moreThanOnePage ? (
                  <LeftRightButton
                    disabled={!canPageLeft}
                    onClick={() => setPage(page - 1)}
                  >
                    &lt;
                  </LeftRightButton>
                ) : null}
                {pageArray.map((pageItem, idx) => {
                  const Button =
                    pageItem && pageItem === page + 1
                      ? PageButtonSelected
                      : PageButton;
                  return pageItem ? (
                    <Button
                      onClick={() => setPage(pageItem - 1)}
                      key={`button_${idx}`}
                    >
                      {pageItem}
                    </Button>
                  ) : (
                    <PageSpacer key={`spacer_${idx}`}>...</PageSpacer>
                  );
                })}
                {moreThanOnePage ? (
                  <LeftRightButton
                    disabled={!canPageRight}
                    onClick={() => setPage(page + 1)}
                  >
                    &gt;
                  </LeftRightButton>
                ) : null}
              </PaginationSection>
            </MainSection>
          </MainArea>
          <Spacer />
          {/*<pre>*/}
          {/*    {programsJson ? JSON.stringify(programsJson.filter(a => a.launched), null, 2) : null}*/}
          {/*</pre>*/}
        </Panel>
      )}
    </SectionContent>
  );
}

export default EventPage;

import styled from "styled-components";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faXTwitter,
  faVimeo,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPodcast,
  faLocationDot,
  faPhone,
  faEnvelope,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { red } from "../styles";

const StyledFooter = styled.footer`
  margin-top: auto;
  text-align: center;
  color: #fff;
  background-color: #002342;
  text-align: left;
  padding-top: 60px;
  padding-bottom: 0px;
  a {
    color: #fff;
    text-decoration: none;
    :visited {
      color: #fff;
    }
    :hover {
      text-decoration: underline;
    }
  }
`;
const FooterContainer = styled.div`
  max-width: 1170px;
`;
const SocialList = styled.ul`
  > li {
    display: inline-block;
    text-align: center;
  }
`;
const ContactInfoList = styled.ul`
  > li {
    display: block;
    margin: 0;
    padding: 0 10px 5px 0;
  }
`;
const FooterList = styled.ul`
  margin-bottom: 40px;
  padding: 0;
  > li {
    display: block;
    margin: 0;
    padding: 0 10px 10px 0px;
  }
`;
const Logo = styled.img`
  margin-bottom: 30px;
`;
const SocialLink = styled.a`
  display: block;
  background-color: ${red};
  border-radius: 50%;
  height: 25px;
  line-height: 25px;
  margin: 2px 7px 0px 0;
  width: 25px;
`;
const Heading = styled.h4`
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.42857143;
  font-weight: 500;
`;
const FooterBottom = styled.div`
  background-color: #001b33;
  padding: 20px 0;
`;
const FooterBottomLeft = styled.div`
  text-align: center;
  margin-left: 0;
  font-size: 14px;
  @media screen and (min-width: 992px) {
    text-align: left;
  }
`;
const FooterBottomRight = styled.div`
  border: 0;
  margin: 0;
  @media screen and (min-width: 992px) {
    text-align: left;
  }
`;
const BottomMenu = styled.ul`
  font-size: 14px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  justify-content: right;
  > li {
    display: inline-block;
    margin-right: 5px;
  }
`;

const currentYear = new Date().getFullYear();

function Footer({ linkPrefix }) {
  return (
    <StyledFooter id="footer" aria-label="footer section">
      <FooterContainer className="container no-break">
        <div className="row">
          <div className="col-sm-6 col-md-3">
            <div className="widget dark">
              <Logo
                alt="SOT logo white"
                src="/images/SOT-Logo_white.png"
                className="no-print"
              />
              <ContactInfoList className="list-inline">
                <li>
                  {" "}
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="text-gray mr-5"
                  />{" "}
                  <a href="tel:+17034383115">
                    11190 Sunrise Valley Dr., Suite 300, Reston, VA 20191
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <FontAwesomeIcon
                    icon={faPhone}
                    className="text-gray mr-5"
                  />{" "}
                  <a href="tel:+17034383115">703.438.3115</a>{" "}
                </li>
                <li>
                  {" "}
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="text-gray mr-5"
                  />{" "}
                  <a id="footer-email" href="mailto:sothq@toxicology.org">
                    sothq@toxicology.org
                  </a>{" "}
                </li>
              </ContactInfoList>
              <SocialList className="styled-icons icon-sm icon-dark icon-theme-colored2 icon-circled clearfix mt-10 p-0 no-print">
                <li>
                  <SocialLink
                    href="https://www.facebook.com/societyoftoxicology"
                    target="_blank"
                    rel="noopener"
                  >
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      aria-label="facebook icon"
                      className="text-white"
                    />
                  </SocialLink>
                </li>
                <li>
                  <SocialLink
                    href="https://www.instagram.com/sotoxicology"
                    target="_blank"
                    rel="noopener"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      aria-label="instagram icon"
                      className="text-white"
                    />
                  </SocialLink>
                </li>
                <li>
                  <SocialLink
                    href="https://www.linkedin.com/company/society-of-toxicology-sot-/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      aria-label="linkedin icon"
                      className="text-white"
                    />
                  </SocialLink>
                </li>
                <li>
                  <SocialLink
                    href="https://www.adversereactionspodcast.com"
                    target="_blank"
                    rel="noopener"
                  >
                    <FontAwesomeIcon
                      icon={faPodcast}
                      aria-label="podcast icon"
                      className="text-white"
                    />
                  </SocialLink>
                </li>
                <li>
                  <SocialLink
                    href="https://twitter.com/SOToxicology"
                    target="_blank"
                    rel="noopener"
                  >
                    <FontAwesomeIcon
                      icon={faXTwitter}
                      aria-label="twitter icon"
                      className="text-white"
                    />
                  </SocialLink>
                </li>
                <li>
                  <SocialLink
                    href="https://vimeo.com/toxicology"
                    target="_blank"
                    rel="noopener"
                  >
                    <FontAwesomeIcon
                      icon={faVimeo}
                      aria-label="vimeo icon"
                      className="text-white"
                    />
                  </SocialLink>
                </li>
                <li>
                  <SocialLink
                    href="https://www.youtube.com/channel/UCzyLrV6LQyO41m1E9NA8qCw"
                    target="_blank"
                    rel="noopener"
                  >
                    <FontAwesomeIcon
                      icon={faYoutube}
                      aria-label="YouTube icon"
                      className="text-white"
                    />
                  </SocialLink>
                </li>
              </SocialList>
            </div>
          </div>
          <div className="col-md-1"> </div>
          <div className="col-sm-6 col-md-2"> </div>
          <div className="col-sm-6 col-md-3 no-print">
            <div className="widget dark">
              <Heading className="widget-title">Members-Only Access</Heading>
              <FooterList className="mb-40">
                <li>
                  <FontAwesomeIcon icon={faCaretRight} />{" "}
                  <a
                    href="https://toxchange.toxicology.org/"
                    target="_blank"
                    rel="noopener"
                  >
                    ToXchange
                  </a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCaretRight} />{" "}
                  <a href={`${linkPrefix}/memberProfile.asp`}>Member Account</a>
                </li>
              </FooterList>
              <Heading className="widget-title">Engage</Heading>
              <FooterList className="">
                <li>
                  <FontAwesomeIcon icon={faCaretRight} />{" "}
                  <a href={`${linkPrefix}/support.asp`}>Support</a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCaretRight} />{" "}
                  <a href={`${linkPrefix}/volunteer`}>Volunteer</a>
                </li>
              </FooterList>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 no-print">
            <div className="widget dark">
              <Heading className="widget-title ">
                SOT Members and Groups
              </Heading>
              <FooterList className="footer-list">
                <li>
                  <FontAwesomeIcon icon={faCaretRight} />{" "}
                  <a href={`${linkPrefix}/groups/rc/rc.asp`}>
                    Regional Chapters
                  </a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCaretRight} />{" "}
                  <a href={`${linkPrefix}/groups/sig/sig.asp`}>
                    Special Interest Groups
                  </a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCaretRight} />{" "}
                  <a href={`${linkPrefix}/groups/ss/ss.asp`}>
                    Specialty Sections
                  </a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCaretRight} />{" "}
                  <a href={`${linkPrefix}/groups/postdoc/postdoc.asp`}>
                    Postdocs
                  </a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCaretRight} />{" "}
                  <a href={`${linkPrefix}/groups/gs/graduates.asp`}>
                    Graduate Students
                  </a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCaretRight} />{" "}
                  <a href={`${linkPrefix}/groups/ug/affiliates.asp`}>
                    Undergraduate Students
                  </a>
                </li>
              </FooterList>
            </div>
          </div>
        </div>
      </FooterContainer>
      <FooterBottom>
        <FooterContainer className="container">
          <div className="row">
            <div className="col-md-6">
              <FooterBottomLeft>
                Copyright &copy; &nbsp;{currentYear}&nbsp; - Society of
                Toxicology. All rights reserved.
              </FooterBottomLeft>
            </div>
            <div className="col-md-6 text-right">
              <FooterBottomRight>
                <BottomMenu>
                  <li className="footer-privacy">
                    {" "}
                    <a href={`${linkPrefix}/privacy.asp`} className="underline">
                      Privacy Policy and Disclaimer
                    </a>{" "}
                  </li>
                  <li>|</li>
                  <li className="footer-faq">
                    {" "}
                    <a href={`${linkPrefix}/help.asp`} className="underline">
                      Help/FAQs
                    </a>{" "}
                  </li>
                </BottomMenu>
              </FooterBottomRight>
            </div>
          </div>
        </FooterContainer>
      </FooterBottom>
    </StyledFooter>
  );
}

export default Footer;

import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { blue, red } from "../../styles";
import {
  faAdd,
  faEdit,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { listEndowments } from "../../api";
import { v4 as uuidv4 } from "uuid";
import { uploadImage } from "../../services/uploadImage";
import {
  allComponentGroups,
  componentGroupTypesByComponentGroup,
} from "./componentGroups";

const SectionContent = styled.div``;
const Panel = styled.div`
  text-align: left;
  h2 {
    color: ${blue} !important;
  }
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;
const LinkWrapper = styled.span`
  margin-left: 5px;
`;
const Button = styled.a`
  margin-left: 5px;
  cursor: pointer;
`;
const P = styled.p`
  margin-bottom: 40px;
`;
const Table = styled.div`
  display: table;
`;
const Row = styled.div`
  display: table-row;
`;
const Cell = styled.div`
  display: table-cell;
  border: 1px solid grey;
  padding: 5px 10px;
`;
const CellButtons = styled.div`
  display: table-cell;
  border: 1px solid grey;
  padding: 5px 10px;
  white-space: nowrap;
`;
const CellContent = styled.span``;

const Field = styled.div`
  width: 80%;
  display: flex;
  margin-bottom: 5px;
`;
const Input = styled.input`
  margin-left: 5px;
  flex: 1;
`;
const Label = styled.label`
  text-align: right;
  width: 215px;
`;
const SmallInput = styled.input`
  margin-left: 5px;
  min-width: 200px;
`;
const FileInput = styled.input`
  margin-left: 5px;
`;
const SmallSelect = styled.select`
  margin-left: 5px;
  min-width: 200px;
`;
const SmallCheckbox = styled.input`
  margin-left: 5px;
`;
const Select = styled.select`
  margin-left: 5px;
  flex: 1;
`;
const Image = styled.img`
  max-width: 200px;
  max-height: 200px;
`;
const CgWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const CgButton = styled.button`
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function EndowmentListAdmin() {
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(1);
  const [endowments, setEndowments] = useState([]);
  const [error, setError] = useState();

  const [name, setName] = useState("");
  const [contributors, setContributors] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [description, setDescription] = useState("");
  const [fullDescription, setFullDescription] = useState("");
  const [link, setLink] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [tags, setTags] = useState("");

  const [flagResearchExcellence, setFlagResearchExcellence] = useState(false);
  const [flagPromotingDiversity, setFlagPromotingDiversity] = useState(false);
  const [flagMissionFunds, setFlagMissionFunds] = useState(false);
  const [flagTravel, setFlagTravel] = useState(false);
  const [flagDevelopment, setFlagDevelopment] = useState(false);
  const [careerLevel, setCareerLevel] = useState("");
  const [componentGroupType, setComponentGroupType] = useState("");
  const [componentGroupNameArray, setComponentGroupNameArray] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const result = await listEndowments();
        const endowments = await result.json();
        setEndowments(endowments);
      } catch (e) {
        setError(
          "Unfortunately an error occurred while retrieving this resource"
        );
      }
      setIsLoading(false);
    })();
  }, [reload]);

  const onUpdateName = (e) => {
    let v = e.target.value;
    setName(v);
  };
  const onUpdateDescription = (e) => {
    let v = e.target.value;
    setDescription(v);
  };
  const onUpdateFullDescription = (e) => {
    let v = e.target.value;
    setFullDescription(v);
  };
  const onUpdateLink = (e) => {
    let v = e.target.value;
    setLink(v);
  };
  const onUpdateImageFile = async (e) => {
    if (
      !e ||
      !e.target ||
      !e.target.files ||
      !e.target.files.length ||
      !e.target.files[0]
    ) {
      return;
    }
    setImageUrl(null);

    let file = e.target.files[0];
    setImageFile(file);
    let url = await uploadImage(file, file.name);
    setImageUrl(url);
  };
  const onUpdateTags = (e) => {
    let v = e.target.value;
    setTags(v);
  };
  const onUpdateContributors = (e, index) => {
    let v = e.target.value;
    const tempContributors = contributors.concat();
    tempContributors[index] = v;
    setContributors(tempContributors);
  };
  const removeContributor = (index) => {
    const tempContributors = contributors
      .concat()
      .filter((r, idx) => idx !== index);
    setContributors(tempContributors);
  };
  const addContributor = () => {
    const tempContributors = contributors.concat();
    tempContributors.push("");
    setContributors(tempContributors);
  };
  const onUpdateRecipients = (e, index) => {
    let v = e.target.value;
    const tempRecipients = recipients.concat();
    tempRecipients[index] = v;
    setRecipients(tempRecipients);
  };
  const removeRecipient = (index) => {
    const tempRecipients = recipients
      .concat()
      .filter((r, idx) => idx !== index);
    setRecipients(tempRecipients);
  };
  const addRecipient = () => {
    const tempRecipients = recipients.concat();
    tempRecipients.push("");
    setRecipients(tempRecipients);
  };

  const onUpdateFlagResearchExcellence = (event) => {
    let target = event.target;
    let checked = target.checked;
    setFlagResearchExcellence(checked);
  };
  const onUpdateFlagPromotingDiversity = (event) => {
    let target = event.target;
    let checked = target.checked;
    setFlagPromotingDiversity(checked);
  };
  const onUpdateFlagMissionFunds = (event) => {
    let target = event.target;
    let checked = target.checked;
    setFlagMissionFunds(checked);
  };
  const onUpdateFlagTravel = (event) => {
    let target = event.target;
    let checked = target.checked;
    setFlagTravel(checked);
  };
  const onUpdateFlagDevelopment = (event) => {
    let target = event.target;
    let checked = target.checked;
    setFlagDevelopment(checked);
  };
  const onUpdateCareerLevel = (e) => {
    setCareerLevel(e.target.value);
  };
  const onUpdateComponentGroup = (e) => {
    setComponentGroupType(
      e.target.value ? componentGroupTypesByComponentGroup[e.target.value] : ""
    );
    const newGName = componentGroupNameArray.concat();
    newGName.push(e.target.value ? e.target.value : "");
    setComponentGroupNameArray(newGName);
  };
  const deleteGName = (val) => {
    const newGName = componentGroupNameArray
      .concat()
      .filter((cg) => cg !== val);
    setComponentGroupNameArray(newGName);
  };

  const create = async () => {
    setIsLoading(true);
    let tempContributors = [];
    if (contributors.length) {
      tempContributors = contributors.concat().filter((c) => c);
      setContributors(tempContributors);
    }
    let tempRecipients = [];
    if (recipients.length) {
      tempRecipients = recipients.concat().filter((r) => r);
      setRecipients(tempRecipients);
    }
    // name, description, fullDescription, link, imageUrl, tags, contributors, recipients
    let endowment = {
      id: uuidv4(),
      name,
      description,
      fullDescription,
      link,
      imageUrl,
      tags,
      contributors: tempContributors,
      recipients: tempRecipients,
      flagResearchExcellence,
      flagPromotingDiversity,
      flagMissionFunds,
      flagTravel,
      flagDevelopment,
      careerLevel,
      componentGroupType,
      componentGroupNameArray,
    };
    try {
      const result = await fetch(`/api/createEndowment`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(endowment),
      });
      if (result.status === 200) {
        alert("Endowment Created");
        setReload(reload + 1);

        setName("");
        setContributors([]);
        setRecipients([]);
        setDescription("");
        setFullDescription("");
        setLink("");
        setImageUrl("");
        setImageFile(null);
        setTags("");
        setFlagResearchExcellence(false);
        setFlagPromotingDiversity(false);
        setFlagMissionFunds(false);
        setFlagTravel(false);
        setFlagDevelopment(false);
        setCareerLevel("");
        setComponentGroupType("");
        setComponentGroupNameArray([]);
      } else {
        alert("There was an error creating endowment");
      }
    } catch (e) {
      console.log(e);
      alert("Unfortunately an error occurred while saving changes");
    }
    setIsLoading(false);
  };

  const deleteEndowment = async (id) => {
    if (
      window.confirm(
        `Are you sure you want to delete the endowment with name ${id}?`
      )
    ) {
      try {
        const result = await fetch(`/api/deleteEndowment`, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
        });
        if (result.status === 200) {
          alert("Endowment Deleted");
          setReload(reload + 1);
        } else {
          alert("There was an error deleting endowment");
        }
      } catch (e) {
        console.log(e);
        alert("Unfortunately an error occurred while deleting endowment");
      }
    }
  };
  //which fields are required?
  let createEnabled = name && name.trim() && description && description.trim();

  return (
    <SectionContent>
      {isLoading ? (
        <Panel>
          <Loading>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          </Loading>
        </Panel>
      ) : error ? (
        <div>
          <span
            className="alert alert-danger"
            role="alert"
            dangerouslySetInnerHTML={{ __html: error }}
          />
        </div>
      ) : (
        <Panel>
          <Table>
            <Row>
              <Cell></Cell>
              <Cell>
                <CellContent>Name</CellContent>
              </Cell>
              <Cell>
                <CellContent>Description</CellContent>
              </Cell>
              <Cell>
                <CellContent>Full Description</CellContent>
              </Cell>
            </Row>
            {endowments.map((endowment) => (
              <Row key={endowment.id ? endowment.id : endowment.name}>
                <CellButtons>
                  <Link to={`/admin/endowment/${endowment.id}`}>
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="fa-envelope-o"
                      aria-hidden="true"
                    />
                  </Link>
                  <Button onClick={() => deleteEndowment(endowment.id)}>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="fa-envelope-o"
                      aria-hidden="true"
                    />
                  </Button>
                </CellButtons>
                <Cell>
                  <CellContent>{endowment.name}</CellContent>
                </Cell>
                <Cell>
                  <CellContent>{endowment.description}</CellContent>
                </Cell>
                <Cell>
                  <CellContent>{endowment.fullDescription}</CellContent>
                </Cell>
              </Row>
            ))}
          </Table>
          <br />
          <br />
          <h2>Create New Endowment</h2>

          <Field>
            <Label>Name:</Label>
            <SmallInput value={name} onChange={onUpdateName} />
          </Field>
          <Field>
            <Label>Description:</Label>
            <Input value={description} onChange={onUpdateDescription} />
          </Field>
          <Field>
            <Label>Full Description:</Label>
            <Input value={fullDescription} onChange={onUpdateFullDescription} />
          </Field>
          <Field>
            <Label>Link:</Label>
            <SmallInput value={link} onChange={onUpdateLink} />
          </Field>
          <Field>
            <Label>Image:</Label>
            <FileInput type="file" onChange={onUpdateImageFile} />
            {imageFile ? imageFile.name : null}
            {imageUrl ? <Image src={imageUrl} /> : null}
          </Field>

          <Field>
            <Label>Component group:</Label>
            <CgWrap>
              <div style={{ display: "flex" }}>
                {componentGroupNameArray &&
                  componentGroupNameArray.map((cg) => (
                    <CgButton key={cg} onClick={() => deleteGName(cg)}>
                      {cg}
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="fa-envelope-o"
                        aria-hidden="true"
                      />
                    </CgButton>
                  ))}
              </div>
              <SmallSelect
                value={
                  componentGroupNameArray[componentGroupNameArray.length - 1]
                }
                onChange={onUpdateComponentGroup}
              >
                <option componentgrouptype="" value=""></option>
                {Object.keys(allComponentGroups).map((cgType) =>
                  allComponentGroups[cgType].map((cg) => (
                    <option key={cg + "opt"} value={cg}>
                      {cg.replace("_", " ")}
                    </option>
                  ))
                )}
              </SmallSelect>
            </CgWrap>
          </Field>
          <Field>
            <Label>Career level:</Label>
            <SmallSelect value={careerLevel} onChange={onUpdateCareerLevel}>
              <option value=""></option>
              <option value="early">Early Level</option>
              <option value="mid">Mid Level</option>
              <option value="senior">Senior Level</option>
            </SmallSelect>
          </Field>
          <Field>
            <Label>Research excellence achievement:</Label>
            <SmallCheckbox
              type="checkbox"
              value={flagResearchExcellence}
              onChange={onUpdateFlagResearchExcellence}
            />
          </Field>
          <Field>
            <Label>Promoting diversity:</Label>
            <SmallCheckbox
              type="checkbox"
              value={flagPromotingDiversity}
              onChange={onUpdateFlagPromotingDiversity}
            />
          </Field>
          <Field>
            <Label>SOT mission funds:</Label>
            <SmallCheckbox
              type="checkbox"
              value={flagMissionFunds}
              onChange={onUpdateFlagMissionFunds}
            />
          </Field>
          <Field>
            <Label>Travel to attend scientific meeting:</Label>
            <SmallCheckbox
              type="checkbox"
              value={flagTravel}
              onChange={onUpdateFlagTravel}
            />
          </Field>
          <Field>
            <Label>Career development</Label>
            <SmallCheckbox
              type="checkbox"
              value={flagDevelopment}
              onChange={onUpdateFlagDevelopment}
            />
          </Field>
          <Field>
            <Label>Tags:</Label>
            <Input value={tags} onChange={onUpdateTags} />
          </Field>
          <Field>
            <Label>Contributors:</Label>
            {contributors.length
              ? contributors.map((c, cIdx) => (
                  <div key={"contributor" + cIdx}>
                    <SmallInput
                      value={c}
                      onChange={(e) => onUpdateContributors(e, cIdx)}
                    />
                    <Button onClick={() => removeContributor(cIdx)}>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="fa-envelope-o"
                        aria-hidden="true"
                      />
                    </Button>
                  </div>
                ))
              : null}
            <Button onClick={addContributor}>
              <FontAwesomeIcon
                icon={faAdd}
                className="fa-envelope-o"
                aria-hidden="true"
              />
            </Button>
          </Field>
          <Field>
            <Label>Recipients:</Label>
            {recipients.length
              ? recipients.map((r, rIdx) => (
                  <div key={"recipient" + rIdx}>
                    <SmallInput
                      value={r}
                      onChange={(e) => onUpdateRecipients(e, rIdx)}
                    />
                    <Button onClick={() => removeRecipient(rIdx)}>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="fa-envelope-o"
                        aria-hidden="true"
                      />
                    </Button>
                  </div>
                ))
              : null}
            <Button onClick={addRecipient}>
              <FontAwesomeIcon
                icon={faAdd}
                className="fa-envelope-o"
                aria-hidden="true"
              />
            </Button>
          </Field>

          <button onClick={create} disabled={!createEnabled}>
            Create
          </button>
          <br />
          <br />
          <br />
        </Panel>
      )}
    </SectionContent>
  );
}

export default EndowmentListAdmin;

import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { blue, red } from "../../styles";
import {
  faEdit,
  faEye,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { listCourses } from "../../api";
import { coursePath, defaultEventTitles } from "../../constants";

const SectionContent = styled.div``;
const Panel = styled.div`
  text-align: left;
  h2 {
    color: ${blue} !important;
  }
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;
const LinkWrapper = styled.span`
  margin-left: 5px;
`;
const Button = styled.a`
  margin-left: 5px;
  cursor: pointer;
`;
const P = styled.p`
  margin-bottom: 40px;
`;
const Table = styled.div`
  display: table;
`;
const Row = styled.div`
  display: table-row;
`;
const Cell = styled.div`
  display: table-cell;
  border: 1px solid grey;
  padding: 5px 10px;
`;
const CellButtons = styled.div`
  display: table-cell;
  border: 1px solid grey;
  padding: 5px 10px;
  white-space: nowrap;
`;
const CellContent = styled.span``;

const Field = styled.div`
  width: 80%;
  display: flex;
  margin-bottom: 5px;
`;
const Input = styled.input`
  margin-left: 5px;
  flex: 1;
`;
const Label = styled.label`
  width: 100px;
`;
const SmallInput = styled.input`
  margin-left: 5px;
  min-width: 200px;
`;
const Select = styled.select`
  margin-left: 5px;
  flex: 1;
`;

function CourseAdmin() {
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(1);
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState();

  const [code, setCode] = useState("");
  const [sessionCode, setSessionCode] = useState("");
  const [title, setTitle] = useState("");
  const [topic, setTopic] = useState("");
  const [year, setYear] = useState("");
  const [eventTitle, setEventTitle] = useState(defaultEventTitles[defaultEventTitles.length - 1]);
  const [oldSiteKey, setOldSiteKey] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const result = await listCourses();
        const courses = await result.json();
        setCourses(courses);
      } catch (e) {
        setError(
          "Unfortunately an error occurred while retrieving this resource"
        );
      }
      setIsLoading(false);
    })();
  }, [reload]);

  const onUpdateCode = (e) => {
    let v = e.target.value;
    setCode(v);
  };
  const onUpdateSessionCode = (e) => {
    let v = e.target.value;
    setSessionCode(v);
  };
  const onUpdateTitle = (e) => {
    let v = e.target.value;
    setTitle(v);
  };
  const onUpdateTopic = (e) => {
    let v = e.target.value;
    setTopic(v);
  };
  const onUpdateYear = (e) => {
    let v = e.target.value;
    setYear(v);
  };
  const onUpdateEventTitle = (e) => {
    let v = e.target.value;
    setEventTitle(v);
  };
  const onUpdateOldSiteKey = (e) => {
    let v = e.target.value;
    setOldSiteKey(v);
  };

  const create = async () => {
    setIsLoading(true);
    let course = {
      code,
      title,
      year,
      topic,
      eventTitle,
      sessionCode,
      oldSiteKey,
    };
    try {
      const result = await fetch(`/api/createCourse`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(course),
      });
      if (result.status === 200) {
        alert("Course Created");
        setReload(reload + 1);
      } else {
        alert("There was an error creating course");
      }
    } catch (e) {
      console.log(e);
      alert("Unfortunately an error occurred while saving changes");
    }
    setIsLoading(false);
  };

  const deleteCourse = async (code) => {
    if (
      window.confirm(
        `Are you sure you want to delete the course with code ${code}?`
      )
    ) {
      try {
        const result = await fetch(`/api/deleteCourse`, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code }),
        });
        if (result.status === 200) {
          alert("Course Deleted");
          setReload(reload + 1);
        } else {
          alert("There was an error deleting course");
        }
      } catch (e) {
        console.log(e);
        alert("Unfortunately an error occurred while deleting course");
      }
    }
  };

  let createEnabled =
    code &&
    code.trim() &&
    title &&
    title.trim() &&
    sessionCode &&
    sessionCode.trim() &&
    eventTitle &&
    oldSiteKey;

  return (
    <SectionContent>
      {isLoading ? (
        <Panel>
          <Loading>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          </Loading>
        </Panel>
      ) : error ? (
        <div>
          <span
            className="alert alert-danger"
            role="alert"
            dangerouslySetInnerHTML={{ __html: error }}
          />
        </div>
      ) : (
        <Panel>
          <Table>
            <Row>
              <Cell></Cell>
              <Cell>
                <CellContent>Title</CellContent>
              </Cell>
              <Cell>
                <CellContent>Topic</CellContent>
              </Cell>
              <Cell>
                <CellContent>Year</CellContent>
              </Cell>
            </Row>
            {courses.map((course) => (
              <Row key={course.code}>
                <CellButtons>
                  <Link to={`/admin/course/${course.code}`}>
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="fa-envelope-o"
                      aria-hidden="true"
                    />
                  </Link>
                  <LinkWrapper>
                    <Link to={`/${coursePath}${course.code}`}>
                      <FontAwesomeIcon
                        icon={faEye}
                        className="fa-envelope-o"
                        aria-hidden="true"
                      />
                    </Link>
                  </LinkWrapper>
                  <Button onClick={() => deleteCourse(course.code)}>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="fa-envelope-o"
                      aria-hidden="true"
                    />
                  </Button>
                </CellButtons>
                <Cell>
                  <CellContent>{course.title}</CellContent>
                </Cell>
                <Cell>
                  <CellContent>{course.topic}</CellContent>
                </Cell>
                <Cell>
                  <CellContent>{course.year}</CellContent>
                </Cell>
              </Row>
            ))}
          </Table>
          <br />
          <br />
          <h2>Create New Course</h2>

          <Field>
            <Label>code:</Label>
            <SmallInput value={code} onChange={onUpdateCode} />
          </Field>
          <Field>
            <Label>sessionCode:</Label>
            <SmallInput value={sessionCode} onChange={onUpdateSessionCode} />
          </Field>
          <Field>
            <Label>title:</Label>
            <Input value={title} onChange={onUpdateTitle} />
          </Field>
          <Field>
            <Label>topic:</Label>
            <Input value={topic} onChange={onUpdateTopic} />
          </Field>
          <Field>
            <Label>year:</Label>
            <SmallInput value={year} onChange={onUpdateYear} type="number" />
          </Field>
          <Field>
            <Label>eventTitle:</Label>
            <Select value={eventTitle} onChange={onUpdateEventTitle}>
              {defaultEventTitles.map((title, i) => (
                <option key={i} value={title}>
                  {title}
                </option>
              ))}
            </Select>
          </Field>
          <Field>
            <Label>oldSiteKey:</Label>
            <Input value={oldSiteKey} onChange={onUpdateOldSiteKey} />
          </Field>

          <button onClick={create} disabled={!createEnabled}>
            Create
          </button>
          <br />
          <br />
          <br />
        </Panel>
      )}
    </SectionContent>
  );
}

export default CourseAdmin;

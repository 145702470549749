import styled from 'styled-components';
import React from "react";
import {
    faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ReactMarkdown from "react-markdown";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";

const Span = styled.span`
  margin-left: 5px;
`;
const PDFImg = styled.img`
  height: 24px;
  width: 24px;
`;

function Course({ title, course }) {
    return (
        <ReactMarkdown
            children={`## ${title}\n${course}`}
            components={{
                a: ({href, children, ...props}) => {
                    let icon = null;
                    if ( href && href.toLowerCase().startsWith('mailto:') ) {
                        icon = (
                            <FontAwesomeIcon icon={faEnvelope} className="fa-envelope-o" aria-hidden="true"/>
                        );
                    } else if ( href && ( href.toLowerCase().indexOf('.pdf') >= 0 || href.toLowerCase().indexOf('/book') >= 0 ) ) {
                        icon = (
                            <FontAwesomeIcon icon={faFilePdf} className="fa-envelope-o" aria-hidden="true"/>
                        );
                    }
                    return (
                        <a {...props} href={href}>
                            <span dangerouslySetInnerHTML={{__html: children}}/>
                            {icon ? (<Span>{icon}</Span>) : null}
                        </a>
                    );
                },
                code: ({className, children, ...props}) => {
                    if (className === 'language-iframe') {
                        return (
                            <iframe
                                src={children[0].trim()}
                                width="640"
                                height="360"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                                referrerPolicy="strict-origin"
                            />
                        );
                    } else {
                        return (
                            <code {...props} className={className}>
                                {children}
                            </code>
                        );
                    }
                }
            }}
        />
    );
}

export default Course;

import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { blue, red } from "../../styles";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";
import Course from "./Course";
import { getCourse } from "../../api";
import { defaultEventTitles } from "../../constants";

const SectionContent = styled.div``;
const LinkWrapper = styled.div`
  text-align: left;
  margin-bottom: 20px;
`;
const Panel = styled.div`
  text-align: left;
  h2 {
    color: ${blue} !important;
  }
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;
const Button = styled.a`
  margin: 15px 15px 10px 0;
`;
const P = styled.p`
  margin-bottom: 40px;
`;
const Field = styled.div`
  width: 80%;
  display: flex;
  margin-bottom: 5px;
`;
const Input = styled.input`
  margin-left: 5px;
  flex: 1;
`;
const Label = styled.label`
  width: 100px;
`;
const SmallInput = styled.input`
  margin-left: 5px;
  min-width: 200px;
`;
const Select = styled.select`
  margin-left: 5px;
  flex: 1;
`;

function CourseAdmin() {
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState([]);
  const [error, setError] = useState();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  let { course } = useParams();

  const [sessionCode, setSessionCode] = useState("");
  const [title, setTitle] = useState("");
  const [topic, setTopic] = useState("");
  const [year, setYear] = useState("");
  const [eventTitle, setEventTitle] = useState(defaultEventTitles[defaultEventTitles.length - 1]);
  const [oldSiteKey, setOldSiteKey] = useState("");

  const onUpdateTitle = (e) => {
    setTitle(e.target.value);
  };
  const onUpdateSessionCode = (e) => {
    setSessionCode(e.target.value);
  };
  const onUpdateTopic = (e) => {
    setTopic(e.target.value);
  };
  const onUpdateYear = (e) => {
    setYear(e.target.value);
  };
  const onUpdateEventTitle = (e) => {
    setEventTitle(e.target.value);
  };
  const onUpdateOldSiteKey = (e) => {
    setOldSiteKey(e.target.value);
  };

  useEffect(() => {
    (async () => {
      try {
        const result = await getCourse(course, {
          useAdmin: true,
        });
        if (result.status === 401) {
          navigate("/login?redirect=" + encodeURIComponent(pathname + search));
        } else if (result.status === 403) {
          setError(
            "If you have not registered for this course and would like access to the course materials, please register on the <a href='https://www.toxicology.org/education/ce/onlineCourses.asp'>CEd-Tox page</a>."
          );
        } else if (result.status === 404) {
          setError("Sorry, the resource was not found.");
        } else {
          const {
            content,
            title,
            sessionCode,
            topic,
            year,
            eventTitle,
            oldSiteKey,
          } = await result.json();
          setContent(content);
          setTitle(title);
          setSessionCode(sessionCode);
          setTopic(topic);
          setYear(year);
          eventTitle && eventTitle.trim() && setEventTitle(eventTitle);
          setOldSiteKey(oldSiteKey);
        }
      } catch (e) {
        setError(
          "Unfortunately an error occurred while retrieving this resource"
        );
      }
      setIsLoading(false);
    })();
  }, []);

  const save = async () => {
    setIsLoading(true);
    try {
      const result = await fetch(`/api/setCourse`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: course,
          content,
          title,
          year,
          topic,
          eventTitle,
          sessionCode,
          oldSiteKey,
        }),
      });
      alert(
        result.status === 200
          ? "Content Saved"
          : "There was an error saving changes"
      );
    } catch (e) {
      console.log(e);
      alert("Unfortunately an error occurred while saving changes");
    }
    setIsLoading(false);
  };

  return (
    <SectionContent>
      <LinkWrapper>
        <Link to="/admin/course">&lt; Back to Course List</Link>
      </LinkWrapper>
      {isLoading ? (
        <Panel>
          <Loading>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          </Loading>
        </Panel>
      ) : error ? (
        <div>
          <span
            className="alert alert-danger"
            role="alert"
            dangerouslySetInnerHTML={{ __html: error }}
          />
        </div>
      ) : (
        <Panel>
          <Field>
            <Label>code:</Label>
            <SmallInput value={course} disabled={true} />
          </Field>
          <Field>
            <Label>sessionCode:</Label>
            <SmallInput value={sessionCode} onChange={onUpdateSessionCode} />
          </Field>
          <Field>
            <Label>title:</Label>
            <Input value={title} onChange={onUpdateTitle} />
          </Field>
          <Field>
            <Label>topic:</Label>
            <Input value={topic} onChange={onUpdateTopic} />
          </Field>
          <Field>
            <Label>year:</Label>
            <SmallInput value={year} onChange={onUpdateYear} type="number" />
          </Field>
          <Field>
            <Label>eventTitle:</Label>
            <Select value={eventTitle} onChange={onUpdateEventTitle}>
              {defaultEventTitles.map((title, i) => (
                <option key={i} value={title}>
                  {title}
                </option>
              ))}
            </Select>
          </Field>
          <Field>
            <Label>oldSiteKey:</Label>
            <Input value={oldSiteKey} onChange={onUpdateOldSiteKey} />
          </Field>

          <br />
          <MDEditor value={content} onChange={setContent} preview="edit" />
          <button onClick={save}>Submit</button>
          <br />
          <br />
          <br />

          <Course title={title} course={content} />
        </Panel>
      )}
    </SectionContent>
  );
}

export default CourseAdmin;

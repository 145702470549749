import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { blue, orange, red } from "../styles";
import { coursePath } from "../constants";
import { getCourse } from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { processAction } from "../services/loginAction";

const Panel = styled.div`
  text-align: left;
  h2 {
    color: ${blue} !important;
    font-weight: 600;
  }
`;
const H2 = styled.h2`
  margin-top: 40px;
  color: ${blue};
  font-weight: 600;
  line-height: 1.42857143;
`;
const Button = styled.a`
  background-color: ${orange};
  border-color: ${orange};
  margin-bottom: 40px;
`;
const P = styled.p`
  margin-bottom: 40px;
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;
const Page = styled.div`
  text-align: left;
`;
const LoginButton = styled.button`
  color: white;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid ${red};
  border-radius: 0.375rem;
  background-color: ${red};
  opacity: 0.9;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
  &:not([disabled]):focus,
  &:not([disabled]):hover {
    opacity: 0.8;
  }
`;

function Login() {
  const { search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [oldSiteKey, setOldSiteKey] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  let query = new URLSearchParams(search);
  let redirect = query.get("redirect");
  let action = query.get("action");

  useEffect(() => {
    (async () => {
      if (redirect && redirect.indexOf(coursePath) >= 0) {
        let courseCode = redirect.substring(
          redirect.indexOf(coursePath) + coursePath.length
        );
        courseCode =
          courseCode.indexOf("/") > 0
            ? courseCode.substring(0, courseCode.indexOf("/"))
            : courseCode;
        try {
          const result = await getCourse(courseCode, {
            titleOnly: true,
          });

          if (result.status === 403) {
            setError(
              "If you have not registered for this course and would like access to the course materials, please register on the <a href='https://www.toxicology.org/education/ce/onlineCourses.asp'>CEd-Tox page</a>."
            );
          } else if (result.status === 404) {
            setError("Sorry, the resource was not found.");
          } else {
            const { title, oldSiteKey } = await result.json();
            setTitle(title);
            setOldSiteKey(oldSiteKey);
          }
        } catch (e) {
          console.log("e", e);
          setError("Something went wrong loading course info.");
        }
      }
      setLoading(false);
    })();
  }, []);

  const submit = async () => {
    try {
      setError("");
      let result = await fetch(`/api/login`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      let { errorMessage } = await result.json();
      if (errorMessage || result.status === 401) {
        setError("Invalid email or password");
      } else {
        if (action) {
          setLoading(true);
          let actionResult = await processAction(action, navigate);
          if (actionResult.message) {
            setError(actionResult.message);
          }
          setLoading(false);
          if (actionResult.processed) {
            return;
          }
        } 
        let url = redirect && redirect.startsWith("/") ? redirect : "/";
        navigate(url, { replace: true });
        navigate(0);
      }
    } catch (e) {
      setError("An error occurred when attempting to login");
    }
  };
  return (
    <Page>
      {loading ? (
        <Panel>
          <Loading>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          </Loading>
        </Panel>
      ) : (
        <React.Fragment>
          {title ? (
            <div className="row">
              <div className="col-xs-12">
                <p id="login-intro">
                  Access to course content is limited to individuals who have
                  registered for this course.
                </p>
                <H2>Register</H2>
                <p>
                  If you have not registered for <b>{title}</b> and would like
                  access to the course materials, please register using the
                  button below.
                </p>
                <Button
                  className="btn btn-dark btn-theme-colored2 mt-10 mb-30"
                  href={`https://www.aim-hq.com/netForumSOT/eweb/DynamicPage.aspx?Site=SOT&Webcode=EvtRedirector&RegPath=EventRegFees&REg_evt_key=${oldSiteKey}&FreeEvent=0&FundraisingEvent=0`}
                  data-feathr-click-track="true"
                  data-feathr-link-aids='["5f3d5ab794d4456c741321a7"]'
                >
                  Register
                </Button>
                <hr />
                <H2>CE Login</H2>
                <p>
                  If you have already registered for <b>{title}</b> or have an
                  Annual Subscription to the SOT CEd-Tox Online Continuing
                  Education course service, please login using the fields below.
                </p>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-xs-12">
                <p id="login-intro">
                  Login is only available for members of the Society of
                  Toxicology or those participating in SOT events or programs.
                </p>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-xs-12 col-md-4 mb-40">
              <div>
                <div className="form-group">
                  <label htmlFor="SOTlogin">Email:</label>
                  <input
                    id="SOTlogin"
                    name="SOTlogin"
                    className="form-control"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="username"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="SOTpw">Password:</label>
                  <input
                    id="SOTpw"
                    name="SOTpw"
                    className="form-control"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="current-password"
                  />
                </div>

                <br />
                <div className="form-group  mt-10">
                  <LoginButton
                    id="login-submit"
                    type="submit"
                    onClick={submit}
                    disabled={!password || !email}
                    className="mr-10 mb-xs-20"
                  >
                    Login
                  </LoginButton>
                </div>
                {error ? (
                  <React.Fragment>
                    <br />
                    <div className="alert alert-warning" role="alert">
                      {error}
                    </div>
                  </React.Fragment>
                ) : null}
                <div className="clear text-left">
                  <p>
                    <a
                      id="reset-password"
                      className="text-theme-colored"
                      href="https://www.aim-hq.com/netFORUMsot/eweb/DynamicPage.aspx?webcode=ForgotPassword&amp;Site=SOT"
                      data-feathr-click-track="true"
                      data-feathr-link-aids='["5f3d5ab794d4456c741321a7"]'
                    >
                      Reset Password
                    </a>
                  </p>
                </div>
                <br />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </Page>
  );
}

export default Login;

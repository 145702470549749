import styled from 'styled-components';
import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {red} from "../styles";

const Page = styled.main`
  text-align: left;
  color: #3c3b3b;
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red}
`;
const Office = styled.div`
  font-weight: bold;
  font-style: italic;
  font-size: 14px;
  line-height: 1.7;
`;
const Candidate = styled.div`
`;

function Admin({}) {
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState(null);
    const navigate = useNavigate();
    const {
        pathname
    } = useLocation();

    const trigger = async () => {
        setIsLoading(true);
        setResult("");

        let d = new Date();
        try {
            const result = await fetch(`https://sotfunctions.azurewebsites.net/api/netforumeventtoavoliotrigger`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            setIsLoading(false);
            if ( result.status === 200 ) {
                setResult("Successfully transferred " + d.toLocaleDateString());
            } else {
                let response = await result.json();
                setResult(response.message || "An error occurred");
            }
        } catch (e) {
            setIsLoading(false);
            setResult("An Error Occurred " + JSON.stringify(e));
        }
    }

    return (
        <Page>
            <button onClick={trigger} disabled={isLoading}>Trigger Transfer</button>
            {
                isLoading ? (
                    <Loading>
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin"/>
                    </Loading>
                ) : null
            }
            {result ? (<p>{result}</p>) : null}
        </Page>
    );
}

export default Admin;
